import { mainStore } from '../../../../../store'

const dictionary = {
    revenues: {
        Salário: 'Salário',
        Adiantamento: 'Adiantamento ou Vale',
        'Pró Labore': 'Pró-labore ou Receita via PJ',
        Informal: 'Outras Rendas',
        Comissões: 'Comissão',
        Benefícios: 'Benefício (Outros)',
        'Aluguel (Receita)': 'Aluguel',
        Pensão: 'Pensão',
        Bônus: 'Bônus',
        'Não Mesal': 'Renda Extra',
        Outras: 'Outras Rendas',
    },
    fixed: {
        Condomínio: 'Condomínio',
        Luz: 'Luz',
        Aluguel: 'Aluguel',
        Água: 'Água',
        Gás: 'Gás',
        Combo: 'Combo (TV, Internet, Telefone)',
        Internet: 'Internet',
        TV: 'TV',
        'Telefone Fixo': 'Telefone Fixo',
        Celular: 'Celular',
        'Funcionário (diarista, doméstica, babá, etc.)': 'Funcionário Doméstico (Outros)',
        'Profissional (psicólogo, contador, professor particular, etc.)': 'Serviços (Outros)',
        'Educação (escola, faculdade, cursos, etc.)': 'Educação (Fixa)',
        'Atividade Física (academia, lutas, futebol, etc.)': 'Atividades Físicas (Outros)',
        'Atividade Extra (música, bordado, teatro, etc.)': 'Atividades Extras (Outros)',
        'Saúde (convênio médico, odontológico, medicamentos fixos, etc.)': 'Saúde (Fixa)',
        'Taxas (tarifas bancárias, taxas de manutenção, etc.)': 'Taxas bancárias (Outros)',
        'Pet (creche, alimentação, banho, tosa, etc.)': 'Pet (banho, tosa, alimentação)',
        'Impostos (IPTU, IPVA, IR, etc.)': 'Impostos (Outros)',
        'Doações (dízimos, ofertas, outras doações, etc.)': 'Doações',
        'Assinaturas (serviços de streaming de vídeo ou músicas, vinhos, etc.)': 'Assinaturas',
        Outros: 'Diversos (Fixa)',
    },
    variable: {
        'Alimentação (mercado, açougue, padaria, feira, etc.)': 'Alimentação (Outros)',
        'Transporte (combustível, estacionamento, aplicativos de transporte, transporte público, etc.)':
            'Transporte (Outros)',
        'Lazer (restaurantes e bares, pedido no delivery, cinema e teatro, etc.)': 'Lazer (Outros)',
        'Saúde e Estética (farmácia, salão, barbearia, manicure, etc.)': 'Saúde (Variável)',
        'Compras e Presentes (vestuário, itens para casa, acessórios, etc.)': 'Compras (Outros)',
        Pet: 'Pet (Outros)',
        Outros: 'Diversos (Variável)',
    },
}

export default class ProcessExcelData {
    store = mainStore()

    worksheet
    transactionData = {
        revenues: [],
        fixed: [],
        variable: [],
    }

    currentRow = 7
    reachEnd = false

    constructor(worksheet) {
        this.worksheet = worksheet
    }

    /**
     *
     * @param {string} threshrold
     */
    searchFinalRow(threshrold) {
        while (!this.reachEnd) {
            let row = this.worksheet.getRow(this.currentRow)

            if (!row || row?.getCell(2)?.value === threshrold) this.reachEnd = true
            else if (!threshrold && !row?.getCell(2)?.value) this.reachEnd = true
            else this.currentRow++
        }
    }

    processRevenues() {
        let rows = []

        const revenuesCategories = this.store.filteredCategories('revenues', this.store.selectedUser)
        const defaultCategory = revenuesCategories.find((c) => c.name === 'Outras rendas')
        let startRow = this.currentRow

        this.searchFinalRow('Despesas Fixas')

        rows.push(...this.worksheet.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let [categoryName, description, value] = [row.getCell(2).value, row.getCell(3).value, row.getCell(4).value]

            if (value && typeof value === 'number') {
                const category = revenuesCategories.find((c) => c.name === dictionary.revenues[categoryName])
                this.transactionData.revenues.push({
                    name: category?.name || defaultCategory.name,
                    category: category?._id || defaultCategory._id,
                    description,
                    value: value.toFixed(2),
                })
            }
        })
    }

    processFixed() {
        this.reachEnd = false

        let rows = []

        const fixedCategories = this.store.newGroupedCategories(true, true, this.store.selectedUser)[0].data
        const defaultCategory = fixedCategories.find((c) => c.name === 'Diversos (Fixa)')
        let startRow = this.currentRow

        this.searchFinalRow('Despesas Variáveis')

        rows.push(...this.worksheet.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let [categoryName, description, value] = [row.getCell(2).value, row.getCell(3).value, row.getCell(4).value]

            if (value && typeof value === 'number') {
                const category = fixedCategories.find((c) => c.name === dictionary.fixed[categoryName])
                this.transactionData.fixed.push({
                    name: category?.name || defaultCategory.name,
                    category: category?._id || defaultCategory._id,
                    description,
                    value: value.toFixed(2),
                })
            }
        })
    }

    processVariable() {
        this.reachEnd = false

        let rows = []

        const variableCategories = this.store.newGroupedCategories(true, true, this.store.selectedUser)[1].data
        const defaultCategory = variableCategories.find((c) => c.name === 'Diversos (Variável)')
        let startRow = this.currentRow

        this.searchFinalRow('')

        rows.push(...this.worksheet.getRows(startRow, this.currentRow - startRow))

        rows.forEach((row, index) => {
            let [categoryName, description, value] = [row.getCell(2).value, row.getCell(3).value, row.getCell(4).value]

            if (value && typeof value === 'number') {
                const category = variableCategories.find((c) => c.name === dictionary.variable[categoryName])
                this.transactionData.variable.push({
                    name: category?.name || defaultCategory.name,
                    category: category?._id || defaultCategory._id,
                    description,
                    value: value.toFixed(2),
                })
            }
        })
    }

    process() {
        this.processRevenues()
        this.processFixed()
        this.processVariable()

        return this.transactionData
    }
}
