<template>
    <div class="planning-progress">
        <month-selector :hide-buttons="hideButtons" @change-month="changeMonth" :change-period="false" />

        <table>
            <thead>
                <th v-for="(title, index) in titles" :key="title">{{ title }}</th>
            </thead>

            <!-- LOADER -->
            <tbody v-if="waiting || disabled">
                <tr class="loading" v-for="(item, index) in data" :key="index">
                    <td :class="{'no-scale': typeof item[key] !== 'number'}" v-for="(key, index) in Object.keys(item)" :key="key">
                        <v-skeleton-loader v-if="typeof item[key] === 'number'" :width="80" type="text" />
                        <td v-else>{{ item[key] }}</td>
                    </td>
                    <td><v-skeleton-loader :width="20" type="text" /></td>
                </tr>
            </tbody>
            <!-- LOADER -->

            <tbody v-else>
                <tr v-for="(item, index) in data" :key="index">
                    <td v-for="(key, index) in Object.keys(item)" :key="key">
                        {{
                            typeof item[key] === 'number'
                                ? formatNumber(item.type !== 'Saldo' ? Math.abs(item[key]) : item[key])
                                : item[key]
                        }}
                    </td>
                    <td>{{ Math.abs(percentage(item.real, item.planned)).toFixed(0) + '%' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue'
import { mainStore } from '../../store'
import axios from 'axios'

import MonthSelector from '../headers/MonthSelector.vue'

import util from '../../common/util'
import apiService from '../../services/api.service'
import { environment } from '../../common/environment'
import formatDate from '../../common/formatDate'

const store = mainStore()

const titles = ref(['Tipo', 'Realizado', 'Previsto', '%'])
const hideButtons = ref([false, false])

const transactions = ref(null)
const planning = ref(null)
const invoices = ref(null)
const arrowMovment = ref(0)

const waiting = computed(() => store.waiting)
const disabled = computed(() => store.disabled)
const data = computed(() => {
    return [
        {
            type: 'Fixas',
            ...store.balancePerCategoryGroup(transactions.value, planning.value, invoices.value).essential,
        },
        {
            type: 'Variaveis',
            ...store.balancePerCategoryGroup(transactions.value, planning.value, invoices.value).noessential,
        },
        {
            type: 'Investimentos',
            ...store.balancePerCategoryGroup(transactions.value, planning.value, invoices.value).investiment,
        },
        {
            type: 'Dividas e parcelas',
            ...store.balancePerCategoryGroup(transactions.value, planning.value, invoices.value).debts,
        },
        {
            type: 'Receitas',
            ...store.balancePerCategoryGroup(transactions.value, planning.value, invoices.value).revenues,
        },
        {
            type: 'Total Despesas',
            ...store.balancePerCategoryGroup(transactions.value, planning.value, invoices.value).total,
        },
        {
            type: 'Saldo',
            ...store.balancePerCategoryGroup(transactions.value, planning.value, invoices.value).balance,
        },
    ]
})

const formatNumber = util.formatNumber
const percentage = util.percentage
const getMonth = formatDate.getMonth
const getYear = formatDate.getYear


const changeMonth = (add) => {
    arrowMovment.value += add

    const movedLeft = add === -1 && arrowMovment.value === -1
    const movedRight = add === 1 && arrowMovment.value === 1


    if (movedLeft || movedRight) {
        hideButtons.value = movedLeft ? [true, false] : [false, true]

        store.DISABLED()

        let query = `?startDate=${store._date_selected.inicio}&endDate=${store._date_selected.fim}`

        if (store.isOttoFamily) query = query.concat(`&userId=${store.mainUserId}`)

        apiService
            .fetch([
                `${environment.TRANSACTION_URL}${query}`, 
                `${environment.API_URL}budgets${query}`, 
                `${environment.INVOICE_URL}?ignoreInvoicePeriod=true&month=${getMonth(store._date_selected.inicio, true)}&year=${getYear(store._date_selected.inicio, true)}`
            ])
            .then(
                axios.spread((transactionsData, planningsData, invoicesData) => {
                    transactions.value = transactionsData.data.items
                    planning.value = planningsData.data.items[0] || {}
                    invoices.value = invoicesData.data.items
                }),
            )
            .catch(console.error)
            .finally(() => store.DISABLED())
    } else {
        hideButtons.value = [false, false]
        transactions.value = planning.value = invoices.value = null
    }
}

onBeforeUnmount(() => store.setSelectedDate(store._date))
</script>

<style lang="scss" scoped>
.planning-progress {
    width: 345px;

    .month-selector {
        transform: scale(0.8);
        margin: 0 auto 12px;
    }

    table {
        font-size: 12px;
        width: 100%;
        color: $darker-blue;

        thead {
            background: #eaeffb;
            color: $grey;
        }

        tr {
            &:nth-child(even) {
                background: $lighter-grey;
            }

            &:nth-child(6) {
                background: #eaeffb;
            }

            &:nth-child(n + 5) {
                border-top: 1px solid $darker-blue;
            }
        }

        td:nth-child(n + 2) {
            text-align: right;
            vertical-align: middle;
        }

        td:nth-child(2),
        td:nth-child(3) {
            font-weight: 500;
        }

        th {
            text-align: left;
        }

        th,
        td {
            padding: 8px;
        }

        td:first-child {
            width: 80px;
        }

        tr.loading > td {
            padding: 0px;
            transform: scaleY(0.8);

            &.no-scale {
                transform: unset;
            }
        }
    }
}

@media screen and (max-width: 780px) {
    .planning-progress {
        width: unset;
    }
}
</style>
