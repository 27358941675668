export const planningDictionary = {
    fixed: 'essentialCategories',
    variable: 'noessentialCategories',
    debts: 'debtsCategories',
    revenues: 'revenuesCategories',
    investiment: 'investimentCategories',
}

export const editPlanningDictionary = {
    fixed: 'essential',
    variable: 'noessential',
    debts: 'debts',
    revenues: 'revenues',
    investiment: 'investiment',
}

/**
 *
 * @param {any} planningCategory - categoria
 * @returns {string}
 */
export const getCategoryPlanningGroup = (planningCategory) => {
    let { type, group, sort } = planningCategory.category?.category || planningCategory.category

    if (group === 'revenues') type = group
    else if (group === 'investiments') type = 'investiment'
    else if (sort) type = sort

    return type
}

export const getAllCategoriesIds = (planning) => {
    const getCategory = (category) => category._id || category

    let essencial = (planning.essentialCategories || []).map((e) => getCategory(e.category))
    let noessential = (planning.noessentialCategories || []).map((ne) => getCategory(ne.category))
    let debts = (planning.debtsCategories || []).map((ne) => getCategory(ne.category))
    let investiments = (planning.investimentCategories || []).map((i) => getCategory(i.category))
    let revenues = (planning.revenuesCategories || []).map((i) => getCategory(i.category))

    return [...essencial, ...noessential, ...investiments, ...debts, ...revenues]
}
