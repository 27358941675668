<template>
    <div class="budgets">
        <div class="planning view-container">
            <div class="view-content">
                <action-bar
                    title="Planejamento do orçamento"
                    :is-transactions="!isEdit"
                    :not-all-option="true"
                    :user-id="selectedUser || mainUserId"
                    @filterByUser="(value) => (selectedUser = value)"
                />

                <button
                    v-if="budget._id"
                    class="preview-btn"
                    :class="{ gutter: isOttoFamily }"
                    @click="showPreview = true"
                >
                    <ion-icon name="bar-chart" class="icon"></ion-icon>
                    <span>{{ wasEdited ? 'Projetar alterações' : 'Projeção anual' }}</span>
                </button>

                <div class="flex" style="margin-bottom: 24px">
                    <div class="flex grow-1 column">
                        <div class="menu-bar flex flex-center box">
                            <month-selector
                                ref="monthSelector"
                                @change-month="changeMonth"
                                :ruleLenght="13"
                                :shift="1"
                                :hideButtons="hideButtons"
                            />
                        </div>

                        <div class="btn-container" v-if="isEdit && this.budget._id">
                            <button class="pill-btn" @click="modalVisible = true">
                                <i class="fas fa-plus"></i>
                                <span>Nova cartegoria</span>
                            </button>
                        </div>

                        <div v-if="waiting">
                            <spending-card :creation="false" :waiting="{ is: true, size: 6 }" />
                        </div>

                        <div v-else-if="customBudgets.total && !budget._id && !waiting">
                            <category-budget-list :budget="customBudgets" @editCategoryEvent="editCustomCategory" />
                        </div>

                        <div class="no-budget-wrapper" v-else-if="!budget._id && !waiting">
                            <div>
                                Mantenha o orçamento sob controle determinando limites para categorias de gastos, ou, se
                                preferir, o {{ $variables.brand }} cria um planejamento automático pra você.
                            </div>

                            <div class="orcamento-bg"></div>

                            <div class="button-wrapper">
                                <!-- <basic-button
                                    label="GERAR PLANEJAMENTO AUTOMÁTICO"
                                    style="margin-bottom: 8px"
                                    @click="() => saveConfirmationCheck(true)"
                                    :width="315"
                                    bg-color="#fa7d20"
                                /> -->
                                <basic-button
                                    label="CRIAR MEU PRÓPRIO PLANEJAMENTO"
                                    @click="distribuitionModalVisible = true"
                                    :width="315"
                                    :reverse="true"
                                    bg-color="#fa7d20"
                                />
                                <small v-if="error">{{ errorMessage }}</small>
                            </div>
                        </div>

                        <div v-else class="flex column">
                            <div v-for="(spendingCard, index) in spendingCardData" :key="index">
                                <spending-card
                                    v-if="budget[spendingCard.field].length > 0"
                                    :categoriesList="budget[spendingCard.field]"
                                    :percentage="totalPercentage"
                                    :length="categoriesBudgetLength"
                                    :revenues="revenuesValue"
                                    :edit="isEdit"
                                    :cardType="spendingCard.cardType"
                                    :title="spendingCard.title"
                                    :subtitle="spendingCard.subtitle"
                                    :notHasRevenues="!budget.isCustom"
                                    @toggleEdition="editBudget"
                                    @cancelEdition="closeEdition"
                                    @updateBudgetEvent="handleEdit"
                                    @delete-row="handleEdit"
                                />
                            </div>

                            <spending-card
                                v-if="budget.investimentCategories.length > 0"
                                :categoriesList="budget.investimentCategories"
                                :percentage="totalPercentage"
                                :length="categoriesBudgetLength"
                                :revenues="revenuesValue"
                                :edit="isEdit"
                                :notHasRevenues="!budget.isCustom"
                                cardType="investiment"
                                title="Investimentos"
                                subtitle="Todo valor guardado, tanto em cardenetas de poupança quanto opções de investimento"
                                :investiments="!budget.investimentCategories.length"
                                @toggleEdition="editBudget"
                                @cancelEdition="closeEdition"
                                @updateBudgetEvent="handleEdit"
                                @delete-row="handleEdit"
                            />
                        </div>
                    </div>

                    <div class="flex column side-column">
                        <summary-model
                            header="Resumo do orçamento"
                            :tableData="summaryData"
                            :chartTitle="budget._id ? 'Equilíbrio do orçamento' : ''"
                            style="margin: 0px; padding-right: 0px"
                        >
                            <template v-slot:progress-bar>
                                <div v-if="budget._id" style="margin: 20px 0 0; justify-content: flex-end; width: 100%">
                                    <!-- LOADER -->
                                    <v-skeleton-loader v-if="waiting" style="margin-top: 20px" type="text" />

                                    <div
                                        v-else
                                        class="row"
                                        style="margin: 0 0 8px; justify-content: space-between; display: flex"
                                    >
                                        <div class="subtitle">Utilização do orçamento</div>

                                        <div class="chart-text percentage">{{ progressBarPercentage.toFixed(0) }}%</div>
                                    </div>
                                </div>

                                <div v-if="!waiting && budget._id" class="progress-bar-border">
                                    <div
                                        class="progress-bar"
                                        :class="progressBarPercentage < 100 ? 'green-background' : 'red-background'"
                                        :style="{ width: progressBarPercentage + '%' }"
                                    ></div>
                                </div>

                                <div
                                    v-if="budget._id"
                                    class="flex"
                                    style="margin: 4px 0; justify-content: flex-end"
                                ></div>
                            </template>

                            <template v-if="budget._id" v-slot:chart>
                                <category-chart
                                    :series="planningChartSeries"
                                    :_options="chartOptions"
                                    :width="300"
                                    :height="180"
                                />
                            </template>
                        </summary-model>

                        <div v-show="budget.user" class="button-container">
                            <basic-button
                                v-if="customBudgets.total > 0"
                                label="ADICIONAR CATEGORIA"
                                :loading="disabled"
                                type="submit"
                                width="100%"
                                @click="() => (modalVisible = true)"
                            />

                            <basic-button
                                v-if="customBudgets.total > 0 && !budget._id"
                                label="SALVAR PLANEJAMENTO"
                                :loading="disabled"
                                :reverse="true"
                                type="submit"
                                width="100%"
                                @click="() => saveConfirmationCheck()"
                            />

                            <basic-button
                                v-if="isEdit"
                                label="Aplicar alterações"
                                :loading="disabled"
                                :disabled="!Object.keys(updatePayload).length"
                                type="submit"
                                width="100%"
                                @click="multiUpdate"
                            />

                            <basic-button
                                v-if="isEdit"
                                label="Descartar alterações"
                                bgColor="#eef1f6"
                                labelColor="#9fa5b1"
                                :loading="disabled"
                                type="submit"
                                width="100%"
                                @click="cancelEdit"
                            />

                            <div v-else-if="!customBudgets.total && budget._id" class="button-container">
                                <basic-button
                                    label="Editar planejamento"
                                    :loading="disabled"
                                    type="submit"
                                    width="100%"
                                    @click="toggleEdition"
                                />

                                <basic-button
                                    label="Reiniciar planejamento"
                                    :loading="disabled"
                                    type="submit"
                                    width="100%"
                                    :reverse="true"
                                    @click="handleDeletePlanning"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <category-budget-modal
            v-if="modalVisible"
            @closeModalEvent="handleEdit"
            :addedCategories="{ ...customBudgets, allIds }"
            :revenues="budget.revenuesValue || customBudgets.revenuesValue"
        />

        <distribution-modal
            v-if="distribuitionModalVisible"
            v-bind="distribuitionModalProps"
            :userId="selectedUser || mainUserId"
            @close-modal="
                () => {
                    distribuitionModalVisible = false
                    distribuitionModalProps = {}
                }
            "
            @save-planning="saveBudget"
        />

        <confirmation-modal-generic v-if="multiConfirmationModal" :message="modalData.text" :closeBtn="false">
            <template #buttons>
                <basic-button :label="modalData.btnLeft" width="100%" @click="() => handleActionLeft()" />

                <basic-button
                    style="margin-top: 10px"
                    :reverse="true"
                    :label="modalData.btnRight"
                    width="100%"
                    @click="() => handleActionRight()"
                />
            </template>
        </confirmation-modal-generic>

        <preview-modal
            v-if="showPreview"
            @close-modal="showPreview = false"
            :budgets="plannings"
            :id="budget._id"
            :previousMonths="this.accumulated"
            @update-planning-list="updatePlanningList"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import ActionBar from '../components/ActionBar.vue'
import SummaryModel from '../components/cards/summary-model/SummaryModel.vue'
import CategoryChart from '../components/charts/CategoryChart.vue'
import SpendingCard from '../components/cards/SpendingCard.vue'
import BasicButton from '../components/buttons/BasicButton.vue'
import ColumnChart from '../components/charts/ColumnChart.vue'
import MonthSelector from '../components/headers/MonthSelector.vue'
import CategoryBudgetList from '../components/lists/CategoryBudgetList.vue'
import CategoryBudgetModal from '../components/modals/CategoryBudgetModal.vue'
import ConfirmationModalGeneric from '../components/modals/confirmation-modals/ConfirmationModalGeneric.vue'
import PreviewModal from '../components/modals/planning/PreviewModal.vue'
import DistributionModal from '../components/modals/planning/distribuition-modal/DistributionModal.vue'

import _date from '../common/formatDate'
import util from '../common/util'
import request from '../common/request'
import { summaryBudget } from '../common/summary_configs'
import apiService from '../services/api.service'
import { environment } from '../common/environment'
import sort from '../common/sort'

import {
    getAllCategoriesIds,
    planningDictionary,
    getCategoryPlanningGroup,
    editPlanningDictionary,
} from './planning/util'

const defaultCustomBudget = {
    essentialCategories: [],
    noessentialCategories: [],
    investiments: [],
    debtsCategories: [],
    revenuesCategories: [],
    allIds: [],
    total: 0,
    budgetTotalValue: 0,
    essentialSpending: 0,
    noessentialSpending: 0,
    investimentSpending: 0,
    debtsSpending: 0,
    revenuesValue: 0,
}

const updateDictionary = {
    essentialCategories: ['essentialSpending', 'essentialValue'],
    noessentialCategories: ['noessentialSpending', 'noessentialValue'],
    debtsCategories: ['debtsSpending', 'debtsValue'],
    investimentCategories: ['investimentSpending', 'investimentValue'],
}

export default {
    name: 'Planning',

    components: {
        ActionBar,
        SummaryModel,
        SpendingCard,
        CategoryChart,
        BasicButton,
        ColumnChart,
        MonthSelector,
        CategoryBudgetModal,
        CategoryBudgetList,
        ConfirmationModalGeneric,
        PreviewModal,
        CategoryChart,
        DistributionModal,
    },

    data() {
        return {
            isEdit: false,
            wasEdited: false, // indica se há alterações projetadas
            budget: {},
            editPayload: {}, // payload de edição
            updatePayload: {},
            allPlannings: [],
            plannings: [],
            noEditedPlannings: [],
            totalPercentage: 0,
            accumulated: 0,
            error: false,
            errorMessage: '',
            multi: false,
            showPreview: false,
            hasPlannings: false,
            defaultBudget: false,
            hideButtons: [false, false],

            allIds: [],

            distribuitionModalVisible: false,
            distribuitionModalProps: {},

            modalVisible: false,
            customBudgets: structuredClone(defaultCustomBudget),

            modalType: '',
            multiConfirmationModal: false,
            modalData: {
                text: '',
                btnLeft: 'Sim',
                btnRight: 'Não',
            },

            selectedUser: '',

            spendingCardData: [
                {
                    field: 'revenuesCategories',
                    cardType: 'revenues',
                    title: 'Receitas',
                },
                {
                    field: 'essentialCategories',
                    title: 'Despesas Fixas',
                    cardType: 'essential',
                    subtitle: 'Aquelas cujo o valor não costuma variar a cadas mês',
                },
                {
                    field: 'noessentialCategories',
                    cardType: 'noessential',
                    title: 'Despesas Variáveis',
                    subtitle: 'Aquelas cujo o valor pode variar mensalmente',
                },
                {
                    field: 'debtsCategories',
                    cardType: 'debts',
                    title: 'Dívidas e parcelas',
                    subtitle: 'Parcelas de financiamento, de compras no cartão e outras dívidas',
                },
            ],
        }
    },

    computed: {
        ...mapState(mainStore, [
            '_date',
            '_date_selected',
            'user',
            'budgets',
            'disabled',
            'waiting',
            'balanceMonthExpectedPerType',
            'getDefautCategoryListByType',
            'getDefautCategoryListBySortType',
            'budgetBalance',
            'planningIncomes',
            'planningChartColors',
            'planningChartSeries',
            'isOttoFamily',
            'mainUserId',
            'stopFillingPlannings',
        ]),

        progressBarPercentage() {
            return this.percentage(this.totalIncome, this.budget.revenuesValue || this.expensesValue)
        },

        expensesValue() {
            let essential = this.budget.essentialCategories || []
            let noessential = this.budget.noessentialCategories || []
            let debts = this.budget.debtsCategories || []
            let investiments = this.budget.investimentCategories || []

            return [...essential, ...noessential, ...debts, ...investiments].reduce((prevValue, category) => {
                return prevValue + category.value
            }, 0)
        },

        customExpensesValue() {
            let essential = this.customBudgets.essentialCategories || []
            let noessential = this.customBudgets.noessentialCategories || []
            let debts = this.customBudgets.debtsCategories || []
            let investiments = this.customBudgets.investiments || []

            return [...essential, ...noessential, ...debts, ...investiments].reduce((prevValue, category) => {
                return prevValue + category.value
            }, 0)
        },

        /**
         * Caso não haja receitas o valor será o total das despesas
         */
        revenuesValue() {
            return this.budget.revenuesValue || this.expensesValue
        },

        /**
         * Caso não haja receitas o valor será o total das despesas
         */
        customRevenuesValue() {
            return this.customBudgets.revenuesValue || this.customExpensesValue
        },

        categoriesBudgetLength() {
            let essential = this.budget.essentialCategories || []
            let noessential = this.budget.noessentialCategories || []
            let debts = this.budget.debtsCategories || []
            let investiments = this.budget.investimentCategories || []

            return essential.length + noessential.length + investiments.length + debts.length
        },

        chartOptions() {
            return {
                labels: [
                    'Despesas fixas',
                    'Despesas variáveis',
                    'Dividas & parcelas',
                    'Investimentos',
                    'Não-reservado',
                ],
                colors: this.planningChartColors,
                plotOptions: {
                    pie: {
                        offsetY: 5,
                        offsetX: 0,
                    },
                },
            }
        },

        // Total reservado
        totalIncome() {
            return (
                this.planningIncomes().essentialIncome +
                this.planningIncomes().noessentialIncome +
                this.planningIncomes().investimentIncome +
                this.planningIncomes().debtsIncome
            )
        },

        totalSpending() {
            return (
                this.budget.essentialSpending +
                this.budget.noessentialSpending +
                this.budget.investimentSpending +
                this.budget.debtsSpending
            )
        },

        totalValue() {
            return (
                (this.budget.essentialValue || 0) +
                (this.budget.noessentialValue || 0) +
                (this.budget.investimentValue || 0) +
                (this.budget.debtsValue || 0)
            )
        },

        summaryData: {
            get() {
                return summaryBudget({
                    accumulated: this.accumulated + this.cumulativeBalance,
                    revenues: this.budget.revenuesValue || 0,
                    expenses: this.totalValue,
                })
            },
            set(summaryBudget) {
                return summaryBudget
            },
        },

        cumulativeBalance() {
            return this.plannings
                .filter((planning) => {
                    const moment = this.$moment(planning.date.slice(0, 10))

                    return (
                        this.$moment(this._date_selected.inicio).isSameOrAfter(moment, 'M') &&
                        !moment.isSame(this.$moment(this._date.inicio).add(-1, 'M'), 'M')
                    )
                })
                .reduce((prevValue, planning) => {
                    const revenues = planning.revenuesValue || 0
                    const spendedValue =
                        planning.essentialValue +
                        planning.noessentialValue +
                        planning.investimentValue +
                        (planning.debtsValue || 0)

                    return prevValue + revenues - spendedValue
                }, 0)
        },
    },

    methods: {
        ...mapActions(mainStore, ['setSelectedDate', 'DISABLED', 'WAITING', 'SET_DATA', 'SET_MISC_DATA']),

        async changeMonth() {
            if (this.isEdit) {
                this.changePlanning()
                return
            }

            this.error = false
            this.DISABLED()
            this.WAITING(true)

            try {
                await this.loadData()
                await this.setBudget()
            } catch (e) {
                console.error(e)
            } finally {
                this.isEdit = this.wasEdited = false
                this.DISABLED()
                this.WAITING()
            }
        },

        async loadData() {
            await request.fetch([{ collection: 'invoices', queryParams: this._date_selected }])
            await this.loadPlannings()
            await request.fetch([{ collection: 'transactions', queryParams: this._date_selected }])
        },

        changePlanning() {
            this.budget =
                this.plannings.find((p) =>
                    this.$moment(p.date.slice(0, 10)).isSame(this.$moment(this._date_selected.inicio), 'M'),
                ) || {}
        },

        async setBudget() {
            const userPlannings = this.allPlannings.filter((planning) => planning.user === this.user._id)

            if (userPlannings.length < 13 && this.hasPlannings && !this.stopFillingPlannings) {
                const resp = await apiService.post(
                    `${environment.API_URL}budgets?fill=true&startDate=${this._date.inicio}&endDate=${this.$moment(
                        this._date.inicio,
                    )
                        .add(11, 'M')
                        .endOf('M')
                        .format('YYYY-MM-DD')}`,
                    {},
                )
                await this.getPlanningBalances()
                this.updatePlanning(resp)
                this.SET_MISC_DATA({ attr: 'stopFillingPlannings', value: true })
            }
        },

        handleDeletePlanning() {
            this.modalData.text = 'Deseja excluir este planejamento?'
            this.modalType = 'delete'
            this.multiConfirmationModal = true
        },

        handleActionLeft() {
            switch (this.modalType) {
                case 'create':
                    this.closeConfirmationModal(true)
                    break
                case 'edit':
                    this.closeConfirmationEditModal(true)
                    break
                case 'delete':
                    this.deleteBudget()
                    break
                default:
            }

            this.multiConfirmationModal = false
            this.modalType = ''
        },

        handleActionRight() {
            switch (this.modalType) {
                case 'create':
                    this.closeConfirmationModal()
                    break
                case 'edit':
                    this.closeConfirmationEditModal()
                    break
                default:
            }

            this.multiConfirmationModal = false
            this.modalType = ''
        },

        saveConfirmationCheck(defaultBudget = false) {
            this.defaultBudget = defaultBudget

            if (!this.hasPlannings) {
                this.multi = true
                this.saveBudget()
            } else {
                this.modalData.text = 'Repetir para os próximos 12 meses?'
                this.multiConfirmationModal = true
            }
        },

        createBudget() {
            const permitedCategories = [
                'compras',
                'cuidadospessoais',
                'lazer',
                'diversos',
                'pets',
                'combotvinternettelefone',
            ]

            let essentialCategories = this.getDefautCategoryListBySortType('fixed')
            let noessentialCategories = this.getDefautCategoryListBySortType('variable', permitedCategories)
            let debtsCategories = this.getDefautCategoryListByType('debts', ['expenses'])
            let revenuesCategories = this.getDefautCategoryListByType(undefined, 'revenues', ['salario'])
            let investimentCategories = this.getDefautCategoryListByType(undefined, 'investiments')

            this.error = false
            this.budget.essentialCategories = this.prepareData(essentialCategories)
            this.budget.noessentialCategories = this.prepareData(noessentialCategories)
            this.budget.debtsCategories = this.prepareData(debtsCategories)
            this.budget.revenuesCategories = this.prepareData(revenuesCategories)
            this.budget.date = new Date(this._date_selected.inicio)
            this.budget.revenuesValue = 0
            this.budget.investimentCategories = this.prepareData(investimentCategories)

            this.WAITING(true)
            request
                .post({ collection: 'budgets', data: this.budget, query: this.multi ? { multi: true } : undefined })
                .catch(console.error)
                .finally(() => {
                    this.defaultBudget = this.multi = false
                    this.WAITING()
                })
        },

        async deleteBudget() {
            this.DISABLED()

            try {
                await request.remove({
                    collection: 'budgets',
                    _id: this.budget._id,
                    queryParams: { startDate: this._date_selected.inicio, endDate: this._date_selected.fim },
                })
                await this.getPlanningBalances()
            } catch (error) {
                console.error(error)
            } finally {
                this.DISABLED()
            }
        },

        async loadPlannings() {
            const startDate = this.$moment(this._date.inicio).add(-1, 'M').format('YYYY-MM-DD')
            const endDate = this.$moment(this._date.inicio).add(11, 'M').endOf('M').format('YYYY-MM-DD')

            const resp = await apiService.get(
                `${environment.API_URL}budgets?startDate=${startDate}&endDate=${endDate}&family=true`,
            )

            this.updatePlanning(resp)
            await this.getPlanningBalances()
        },

        async getPlanningBalances() {
            const lastMonth = this.plannings.find((item) =>
                this.$moment(item.date.slice(0, 10)).isSame(this.$moment(this._date.inicio).add(-1, 'M'), 'M'),
            )

            if (lastMonth) {
                if (lastMonth.accumulated) {
                    this.accumulated = lastMonth.accumulated
                    return
                }
            }

            if (this._date.inicio !== this._date_selected.inicio) return

            const inicio = this.$moment(this._date.inicio).add(-1, 'M').format('YYYY-MM-DD')
            const fim = this.$moment(this._date.inicio).add(-1, 'M').endOf('M').format('YYYY-MM-DD')

            const response = await apiService.get(
                `${environment.BALANCES_URL}?type=budgets&startDate=${inicio}&endDate=${fim}&userId=${this.selectedUser}`,
            )
            this.accumulated = response.data.accumulated
        },

        updatePlanningList(planning) {
            const index = this.plannings.findIndex((p) => p._id === planning._id)

            if (index < 0) this.plannings.push(planning)
            else this.plannings.splice(index, 1, planning)

            this.getPlanningBalances()
        },

        removeDuplicates() {
            let newArray = []

            this.plannings.forEach((planning) => {
                if (!newArray.length) {
                    newArray.push(planning)
                    return
                }

                const index = newArray.findIndex((p) => {
                    return p.date.slice(0, 10) === planning.date.slice(0, 10)
                })
                if (index < 0) newArray.push(planning)
            })

            this.plannings = newArray
        },

        changeUser() {
            const userId = this.selectedUser || this.mainUserId
            const userPlannings = this.allPlannings.filter((planning) => planning.user == userId)

            this.SET_MISC_DATA({ attr: 'selectedUser', value: userId })

            this.plannings = userPlannings.sort(sort.byDate)

            this.removeDuplicates()
            this.noEditedPlannings = [...this.plannings]

            this.SET_DATA({
                attr: 'budgets',
                options: { noUserCheck: true },
                data: this.plannings.filter((budget) => {
                    return this.$moment(this._date_selected.inicio).isSame(this.$moment(budget.date.slice(0, 10)), 'M')
                }),
            })
        },

        updatePlanning(response) {
            this.allPlannings = response.data.items
            this.hasPlannings = response.data.total > 0

            this.changeUser()
        },

        toggleEdition() {
            this.isEdit = true
            this.$refs.monthSelector.lastPeriod = this._date_selected
        },

        percentage(n, d) {
            return util.percentage(n, d)
        },

        prepareData(categories) {
            return categories.map((category) => {
                return (category = { category: category._id, budgetValue: category.defaultBudgetValue })
            })
        },

        deleteRow(budget) {
            this.wasEdited = true

            let index, field, updateIndex

            switch (this.editPayload.type) {
                case 'essential':
                    field = 'essentialCategories'
                    break
                case 'noessential':
                    field = 'noessentialCategories'
                    break
                case 'debts':
                    field = 'debtsCategories'
                    break
                case 'investiment':
                    field = 'investimentCategories'
                    break
                default:
                    field = 'revenuesCategories'
            }

            index = budget[field].findIndex((cb) => cb.category._id === this.editPayload.category)

            if (!this.updatePayload[budget._id]) this.updatePayload[budget._id] = {}

            if (this.updatePayload[budget._id][field])
                updateIndex = this.updatePayload[budget._id][field].findIndex(
                    (cb) => cb.category._id === this.editPayload.category,
                )
            else this.updatePayload[budget._id][field] = []

            if (index > -1) {
                if (field !== 'revenuesCategories') {
                    this.totalPercentage -= budget[field][index].budgetValue
                    budget[updateDictionary[field][0]] -= budget[field][index].budgetValue
                    budget[updateDictionary[field][1]] -= budget[field][index].value
                } else budget.revenuesValue -= budget[field][index].value

                const deletedData = budget[field].splice(index, 1)

                this.replacePlanning(budget)

                if (updateIndex > -1) {
                    if (!this.editPayload._id) this.updatePayload[budget._id][field].splice(updateIndex, 1)
                    else this.updatePayload[budget._id][field][updateIndex].deleted = true
                } else this.updatePayload[budget._id][field].push({ ...deletedData[0], deleted: true })

                if (field === 'revenuesCategories') this.updateEditRevenueValues(budget)

                if (budget._id === this.budget._id) this.budget = budget
            }
        },

        handleEdit(payload) {
            if (!payload) {
                this.modalVisible = false
                return
            }

            this.editPayload = payload
            this.modalData.text = 'Repetir para os próximos meses?'
            this.modalType = 'edit'
            this.multiConfirmationModal = !payload.recurrentType && !payload.lastInstallment

            if (!this.multiConfirmationModal) this.closeConfirmationEditModal()
        },

        async multiUpdate() {
            const userId = this.selectedUser || this.user._id
            const query = this.user._id !== userId ? `?userId=${userId}` : ''

            this.DISABLED()

            // Multi update com multiplas chamadas
            // try {
            //     for (const updateId of Object.keys(this.updatePayload)) {
            //         await apiService.patch(
            //             `${environment.API_URL}budgets/${updateId}${query}`,
            //             this.updatePayload[updateId],
            //         )
            //     }
            // } catch (error) {
            //     console.error()
            // } finally {
            //     this.loadData()
            //         .catch(console.error)
            //         .finally(() => {
            //             this.closeEdition()
            //             this.DISABLED()
            //         })
            // }

            // Multi update com chamada única
            apiService
                .patch(`${environment.API_URL}budgets/multi-update${query}`, this.updatePayload)
                .then(() => this.loadData())
                .catch(console.error)
                .finally(() => {
                    this.closeEdition()
                    this.DISABLED()
                })
        },

        validatePayload() {
            this.DISABLED()

            request
                .patch({
                    collection: 'budgets',
                    data: this.updatePayload[this.budget._id],
                    _id: this.budget._id,
                    queryParams: this.multi
                        ? {
                              multi: this.multi,
                              startDate: this._date_selected.inicio,
                              endDate: this.$moment(this._date_selected.inicio)
                                  .add(11, 'M')
                                  .endOf('M')
                                  .format('YYYY-MM-DD'),
                          }
                        : undefined,
                })
                .then(() => this.loadPlannings())
                .catch(console.error)
                .finally(() => {
                    this.closeEdition()
                    this.DISABLED()
                })
        },

        editBudget() {
            this.isEdit = true
            this.totalPercentage = this.totalSpending

            this.$emit('updateChat', { editBudget: true })
        },

        cancelEdit() {
            this.isEdit = this.wasEdited = false
            this.updatePayload = {}
            this.hideButtons = [false, false]

            this.budget = structuredClone(this.budgets)
            this.plannings = [...this.noEditedPlannings]
            this.$refs.monthSelector.reset()
            this.$emit('updateChart', { editBudget: false })
        },

        replacePlanning(planning) {
            const index = this.plannings.findIndex((budget) => {
                return budget._id === planning._id
            })

            this.plannings[index] = structuredClone(planning)
        },

        updateBudgetPayload(budget) {
            this.wasEdited = true

            switch (this.editPayload.type) {
                case 'essential':
                    this.updateCategoriesArray('essentialCategories', this.editPayload.categoryBudget, budget)
                    budget.essentialSpending = this.editPayload.essentialSpending
                    break
                case 'noessential':
                    this.updateCategoriesArray('noessentialCategories', this.editPayload.categoryBudget, budget)
                    budget.noessentialSpending = this.editPayload.noessentialSpending
                    break
                case 'debts':
                    this.updateCategoriesArray('debtsCategories', this.editPayload.categoryBudget, budget)
                    budget.debtsSpending = this.editPayload.debtsSpending
                    break
                case 'investiment':
                    this.updateCategoriesArray('investimentCategories', this.editPayload.categoryBudget, budget)
                    budget.investimentSpending = this.editPayload.investimentSpending
                    break
                default:
                    this.updateCategoriesArray('revenuesCategories', this.editPayload.categoryBudget, budget)
            }

            if (budget._id === this.budget._id) this.budget = budget

            this.replacePlanning(budget)
        },

        updateCategoriesArray(field, categoryBudget, budget) {
            let index, diff, diffValue, updateIndex

            if (budget[field]) {
                index = budget[field].findIndex((c) => c.category._id === categoryBudget.category._id)

                if (!this.updatePayload[budget._id]) this.updatePayload[budget._id] = { isCustom: true }

                if (this.updatePayload[budget._id][field])
                    updateIndex = this.updatePayload[budget._id][field].findIndex(
                        (c) => c.category._id === categoryBudget.category._id,
                    )
                else this.updatePayload[budget._id][field] = []

                if (index > -1) {
                    diff = categoryBudget.budgetValue - budget[field][index].budgetValue
                    diffValue = categoryBudget.value - (budget[field][index].value || 0)

                    budget[field].splice(index, 1, categoryBudget)

                    if (updateIndex > -1) this.updatePayload[budget._id][field].splice(index, 1, categoryBudget)
                    else this.updatePayload[budget._id][field].push(categoryBudget)

                    if (field !== 'revenuesCategories') {
                        this.totalPercentage += diff
                        budget[updateDictionary[field][1]] += diffValue
                    } else {
                        budget.revenuesValue += diffValue
                        this.updateEditRevenueValues(budget)
                    }
                } else {
                    budget[field].push(categoryBudget)
                    this.updatePayload[budget._id][field].push(categoryBudget)

                    if (field !== 'revenuesCategories') {
                        this.totalPercentage += categoryBudget.budgetValue
                        budget[updateDictionary[field][1]] += categoryBudget.value
                    } else {
                        budget.revenuesValue += categoryBudget.value
                        this.updateEditRevenueValues(budget)
                    }
                }
            }
        },

        closeEdition() {
            this.isEdit = this.wasEdited = this.multi = false
            this.hideButtons = [false, false]
            this.totalPercentage = this.totalSpending
            this.budget = structuredClone(this.budgets)
            this.updatePayload = {}

            this.$emit('updateChat', { editBudget: false })
        },

        addCategory(budget) {
            if (this.editPayload) {
                const {
                    budgetValue,
                    category,
                    value,
                    lastInstallment,
                    recurrentType,
                    notCreateTransaction,
                    day,
                    paid,
                } = this.editPayload

                this.updateCategoriesArray(
                    planningDictionary[getCategoryPlanningGroup(this.editPayload)],
                    {
                        budgetValue,
                        category,
                        value,
                        lastInstallment,
                        recurrentType,
                        notCreateTransaction,
                        day,
                        paid,
                    },
                    budget,
                )

                if (this.budget._id === budget._id) this.budget = budget

                this.replacePlanning(budget)

                this.modalVisible = false
            }
        },

        closeConfirmationModal(multi = false) {
            this.multi = multi
            this.saveBudget()
        },

        editMode(budget) {
            switch (this.editPayload.mode) {
                case 'edit':
                    this.updateBudgetPayload(budget)
                    break
                case 'delete':
                    this.deleteRow(budget)
                    break
                case 'add':
                    this.addCategory(budget)
                    break
                default:
            }

            this.handleRecurrency()
            this.removeEmptyUpdates(budget._id)
        },

        removeEmptyUpdates(budgetId) {
            let haveData = false

            for (const key of [
                'essentialCategories',
                'noessentialCategories',
                'investimentCategories',
                'debtsCategories',
                'revenuesCategories',
            ]) {
                if (!Array.isArray(this.updatePayload[budgetId][key])) continue

                if (this.updatePayload[budgetId][key].length) {
                    haveData = true
                    break
                }
            }

            if (!haveData) delete this.updatePayload[budgetId]
        },

        handleRecurrency() {
            const { lastInstallment, recurrentType } = this.editPayload
            const index = this.plannings.findIndex((planning) => planning._id === this.budget._id)
            let startDate = this.$moment(this.budget.date.slice(0, 10))

            if (index === this.plannings.length - 1) return

            this.editPayload.notCreateTransaction = true

            if (lastInstallment) this.handleRecurrencyInstallments(startDate, lastInstallment)
            if (recurrentType && recurrentType !== 'annual') this.handleRecurrencyPeriod(startDate, recurrentType)
        },

        executeUpdate(startDate) {
            let _budget = this.plannings.find((planning) =>
                this.$moment(planning.date.slice(0, 10)).isSame(startDate, 'M'),
            )

            if (!!_budget) {
                _budget = structuredClone(_budget)

                const planningGroup = getCategoryPlanningGroup(this.editPayload)
                const planningCategory = _budget[planningDictionary[planningGroup]].find(
                    (pC) => pC.category._id === this.editPayload.category._id,
                )

                if (planningCategory) {
                    this.editPayload.mode = 'edit'
                    this.editPayload.type = editPlanningDictionary[planningGroup]
                    this.editPayload.categoryBudget = planningCategory
                    this.editPayload.categoryBudget.value += this.editPayload.value
                    this.editPayload.categoryBudget.budgetValue += this.editPayload.budgetValue
                    this.editMode(_budget)
                } else {
                    this.editPayload.mode = 'add'
                    this.editMode(_budget)
                }
            }
        },

        handleRecurrencyPeriod(startDate, recurrentType) {
            let periodMonths = {
                monthly: 1,
                semiannual: 6,
            }

            startDate.add(periodMonths[recurrentType], 'M')

            delete this.editPayload['recurrentType']

            let endDate = this.$moment(this.budget.date.slice(0, 10)).add(11, 'M')

            while (startDate.isSameOrBefore(endDate)) {
                this.executeUpdate(startDate)
                startDate.add(periodMonths[recurrentType], 'M')
            }
        },

        handleRecurrencyInstallments(startDate, lastInstallment) {
            delete this.editPayload['lastInstallment']
            lastInstallment--

            let endDate = this.$moment(this.budget.date.slice(0, 10)).add(
                lastInstallment > 11 ? 11 : lastInstallment,
                'M',
            )
            startDate.add(1, 'M')

            while (startDate.isSameOrBefore(endDate)) {
                this.executeUpdate(startDate)
                startDate.add(1, 'M')
            }
        },

        closeConfirmationEditModal(multi = false) {
            this.editMode(this.budget)

            if (multi) {
                const initialIndex = this.plannings.findIndex((p) => p._id === this.budget._id) + 1

                if (initialIndex >= this.plannings.length - 1) return

                for (let i = initialIndex; i < this.plannings.length; i++) {
                    this.editMode(structuredClone(this.plannings[i]))
                }
            }
        },

        saveBudget(data) {
            if (this.defaultBudget) {
                this.createBudget()
                return
            }

            let options = {}

            if (this.selectedUser && this.user._id !== this.selectedUser) {
                options.thisUserId = this.selectedUser
                options.query = { userId: this.selectedUser }
            }

            this.DISABLED(true)

            request
                .post({ collection: 'budgets', data, ...options })
                .then(() => this.loadPlannings())
                .catch(console.error)
                .finally(() => {
                    this.multi = this.defaultBudget = this.distribuitionModalVisible = false
                    this.DISABLED()

                    if (!this.user.skipTutorial) this.$router.push('/painel')
                })
        },

        updateCustomRevenueValues() {
            let revenues = this.customBudgets.revenuesValue || 1

            this.customBudgets.revenuesCategories.forEach((cB, index) => {
                this.customBudgets.revenuesCategories[index].budgetValue = (cB.value / revenues) * 100
            })

            Array('essentialCategories', 'noessentialCategories', 'investiments', 'debtsCategories').forEach((key) => {
                this.customBudgets[key].forEach((cB, index) => {
                    this.customBudgets[key][index].budgetValue = (cB.value / (this.customRevenuesValue || 1)) * 100
                })
            })
        },

        updateEditRevenueValues(budget) {
            budget.revenuesCategories.forEach((cB, index) => {
                budget.revenuesCategories[index].budgetValue = (cB.value / (budget.revenuesValue || 1)) * 100

                const _index = this.updatePayload[budget._id].revenuesCategories.findIndex(
                    (c) => c.category._id === cB.category._id,
                )

                if (_index > -1)
                    this.updatePayload[budget._id].revenuesCategories[_index].budgetValue =
                        budget.revenuesCategories[index].budgetValue
                else
                    this.updatePayload[budget._id].revenuesCategories.push({
                        ...cB,
                        budgetValue: budget.revenuesCategories[index].budgetValue,
                    })
            })

            Array('essentialCategories', 'noessentialCategories', 'investimentCategories', 'debtsCategories').forEach(
                (key) => {
                    budget[key].forEach((cB, index) => {
                        const automatic = !budget.isCustom

                        if (automatic)
                            budget[key][index].value = (budget[key][index].budgetValue * this.revenuesValue) / 100
                        else budget[key][index].budgetValue = (cB.value / (this.revenuesValue || 1)) * 100

                        let updates = {
                            budgetValue: budget[key][index].budgetValue,
                        }

                        if (automatic) updates.value = budget[key][index].value

                        if (!!this.updatePayload[budget._id][key]) {
                            const _index = this.updatePayload[budget._id][key].findIndex(
                                (c) => c.category._id === cB.category._id,
                            )

                            if (_index > -1) {
                                this.updatePayload[budget._id][key][_index].budgetValue = budget[key][index].budgetValue
                                if (automatic)
                                    this.updatePayload[budget._id][key][_index].value = budget[key][index].value
                            } else
                                this.updatePayload[budget._id][key].push({
                                    ...cB,
                                    ...updates,
                                })
                        } else
                            this.updatePayload[budget._id][key] = [
                                {
                                    ...cB,
                                    ...updates,
                                },
                            ]
                    })
                },
            )
        },

        editCustomCategory(payload) {
            let diffValue = 0,
                diff = 0,
                index

            if (!payload) return

            if (payload.category.group === 'revenues') {
                index = this.customBudgets.revenuesCategories.findIndex(
                    (ca) => ca.category._id === payload.category._id,
                )

                diff = payload.value - this.customBudgets.revenuesCategories[index].value

                this.customBudgets.revenuesCategories.splice(index, 1, payload)
                this.customBudgets.revenuesValue += diff

                this.updateCustomRevenueValues()
            } else {
                switch (payload.category.sort) {
                    case 'fixed':
                        index = this.customBudgets.essentialCategories.findIndex(
                            (ca) => ca.category._id === payload.category._id,
                        )
                        diff = payload.budgetValue - this.customBudgets.essentialCategories[index].budgetValue

                        this.customBudgets.essentialCategories.splice(index, 1, payload)
                        this.customBudgets.essentialSpending += diff
                        break
                    case 'variable':
                        if (payload.category.name === 'Investimentos' && !payload.category.category) {
                        } else {
                            let index = this.customBudgets.noessentialCategories.findIndex(
                                (ca) => ca.category._id === payload.category._id,
                            )
                            diff = payload.budgetValue - this.customBudgets.noessentialCategories[index].budgetValue

                            this.customBudgets.noessentialCategories.splice(index, 1, payload)
                            this.customBudgets.noessentialSpending += diff
                        }
                        break
                    default:
                        const list =
                            payload.category.group === 'investiments'
                                ? ['investiments', 'investimentSpending']
                                : ['debtsCategories', 'debtsSpending']

                        index = this.customBudgets[list[0]].findIndex((ca) => ca.category._id === payload.category._id)
                        diff = payload.budgetValue - this.customBudgets[list[0]][index].budgetValue

                        this.customBudgets[list[0]].splice(index, 1, payload)
                        this.customBudgets[list[1]] += diff
                }

                if (!this.customBudgets.revenuesValue) this.updateCustomRevenueValues()
            }

            diffValue = (diff / 100) * this.customBudgets.revenuesValue
            this.customBudgets.budgetTotalValue = this.customBudgets.budgetTotalValue + diffValue
        },
    },

    watch: {
        budgets: {
            immediate: true,
            handler(newBudget) {
                if (newBudget) this.budget = structuredClone(newBudget)
            },
        },

        budget: {
            immediate: true,
            handler() {
                this.allIds = []
                this.allIds.push(...getAllCategoriesIds(this.budget))
            },
        },

        selectedUser: {
            handler() {
                this.changeUser()
            },
        },
    },

    mounted() {
        this.selectedUser = this.mainUserId
        this.setSelectedDate(this._date)
        this.changeMonth()

        if (!this.user.skipTutorial) {
            if (this.user.planner) this.distribuitionModalProps = { onboardingClient: true }
            this.distribuitionModalVisible = true
        }
    },
}
</script>

<style lang="scss" scoped>
.grow {
    flex-grow: 1;
}

.column {
    flex-direction: column;
}

.side-column {
    align-items: center;
    padding-left: 24px;
}

.view-content {
    .menu-bar {
        height: 55px;
        padding: 0px 20px 0px;
        margin-bottom: 20px;
        color: $text-black;
        border-top: solid 1px $title-grey;
        border-bottom: solid 1px $title-grey;
        border-radius: 2px;
        text-align: center;
    }

    .box {
        align-items: center;
        text-align: center;
        vertical-align: middle;
    }

    .label-header {
        width: 170px;
        font-weight: 500;
    }

    .icon {
        height: 18.57px;
        max-width: 18.57px;
        color: $title-grey;
    }
}

.no-budget-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    & > div {
        width: 529px;
        color: #737373;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
    }

    .orcamento-bg {
        height: 260px;
        margin: 60px 0 60px;
        background-image: url('../assets/img/planejamento.svg');
        background-position: center;
        background-size: contain;
    }

    .button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        color: #d0021b;
    }
}

.submit-btn {
    height: 56px !important;
    width: 270px;
    color: white !important;
    font-size: 16px !important;
    font-weight: 500;
    border-radius: 4px;
    background: $brand-blue !important;
    position: relative;
}

.chart {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.container {
    .column-chart {
        margin: 16px 0 -16px 0;
    }

    .spendingCard {
        margin-bottom: 18px;
    }
}

.flex {
    display: flex;
}

.grow-1 {
    flex-grow: 1 !important;
}

.flex-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.green-background {
    background-color: $progress-green;
    color: $light-blue;
}

.red-background {
    background: $expense-red;
}

.chart-text {
    font-weight: 600;
    font-size: 10px;
    margin-top: auto;
    margin-bottom: auto;

    &.expenses-color {
        color: $expense-red;
        font-size: 13px !important;
        margin-right: 3px;
    }

    &.revenues-color {
        color: $revenue-green;
        font-size: 13px !important;
        margin-left: 3px;
    }

    &.percentage {
        font-size: 13px !important;
        color: $light-blue;
    }
}

.summary {
    font-weight: 500;
    font-size: 16px;
    color: #1e3351;
    margin-bottom: 16px;
}

.subtitle {
    font-size: 14px;
    color: #617085;
}

.button-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .basic-button {
        width: 270px;

        &:nth-child(2) {
            margin-top: 8px;
        }
    }
}

.pill-btn {
    background-color: $btn-orange;
    color: white;
    margin: 0 0 24px;
    align-self: center;
    font-weight: 400 !important;
    font-size: 14px !important;
    padding: 16px;
    border-radius: 40px;

    span {
        margin-left: 12px;
        top: 2px;
    }
}

.preview-btn {
    position: absolute;
    text-transform: none !important;
    font-weight: 400 !important;
    top: 16px;
    right: 24px;
    color: white;
    background-color: $action-btn-color;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 8px;

    &.gutter {
        right: 64px;
    }

    & > .icon {
        margin-right: 12px;
        color: white;
    }

    & > span {
        margin-top: 3px;
    }
}

.btn-container {
    display: flex;
    justify-content: center;
}

.category-chart {
    margin-top: 20px;
}

@media screen and (max-width: 700px) {
    .menu-bar {
        width: 100%;
    }

    .preview-btn {
        position: relative;
        margin: 0 auto 24px;
        top: 0;
        right: 0;
    }

    .side-column {
        padding-left: 0;
    }
}
</style>
