<template>
    <modal-frame
        class="distribution-modal"
        title="Levantamento de informações financeiras"
        :width="componentProps.width"
        :height="componentProps.height"
        :extBtnClasses="['flex', step < 2 ? 'right' : '']"
        :overlay-styles="componentProps.overlayStyles"
        :content-styles="componentProps.contentStyles"
        :close-btn="componentProps.closeBtn"
        @close-modal="$emit('close-modal')"
    >
        <template v-if="!step" #content>
            <video controls>
                <source src="@/assets/videos/presentation-video.mp4" type="video/mp4" />
                Seu navegador não suporta este formato de vídeo
            </video>
        </template>

        <template v-else #content>
            <distribution-modal-rule v-if="componentProps.showRule" :step="step" />

            <step-messages v-if="step > 5 || !componentProps.showStepMessages" :step="step" />

            <file-upload-button
                :style="{ top: componentProps.uploadButtonPosition + 'px' }"
                v-if="step && step < 6 && !componentProps.showStepMessages"
                accept=".xml,.xlsx"
                @change="readSheet"
            />

            <div v-show="step < 6" v-for="n in 5">
                <step-messages v-if="componentProps.showStepMessages" :step="n" @show-tutorial="step = 0" />

                <file-upload-button
                    :style="{ top: componentProps.uploadButtonPosition + 'px' }"
                    v-if="componentProps.showStepMessages"
                    accept=".xml,.xlsx"
                    @change="readSheet"
                />

                <form-table
                    v-show="componentProps.showAllForms || n === step"
                    :ref="`form-table-${n}`"
                    :key="n"
                    :step="n"
                    :default-rows="componentProps.formRowsQtd[n - 1]"
                    :onboarding-client="onboardingClient"
                    @update-transaction="updateTransaction"
                />
            </div>

            <div class="img-container" v-show="step > 5">
                <img src="@/assets/img/success-onboarding.png" alt="success-onboarding" />
            </div>
        </template>

        <template #btn-container>
            <basic-button
                v-if="step > 1"
                v-bind="btnProps"
                label="Anterior"
                :disabled="disabled"
                :width="btnWidth"
                @click="previousStep"
            />

            <basic-button v-if="step < 6" v-bind="btnProps" :label="midBtnLabel" :width="btnWidth" @click="nextStep" />

            <basic-button
                v-else
                v-bind="btnProps"
                label="Fechar"
                :disabled="disabled"
                :width="btnWidth"
                @click="endOnboarding"
            />
        </template>
    </modal-frame>
</template>

<script>
import { mapState } from 'pinia'
import Excel from 'exceljs'

import ModalFrame from '../../../../containers/ModalFrame.vue'
import DistributionModalRule from './components/DistributionModalRule.vue'
import FormTable from './components/FormTable.vue'
import StepMessages from './components/StepMessages.vue'
import BasicButton from '../../../buttons/BasicButton.vue'
import FileUploadButton from '../../../inputs/FileUploadButton.vue'

import ProcessExcelData from '../distribuition-modal/utils/processExcelData'

import { mainStore } from '../../../../store'
import util from '../../../../common/util'

export default {
    name: 'DistribuitionModal',

    props: {
        onboardingClient: {
            type: Boolean,
        },
    },

    components: {
        ModalFrame,
        DistributionModalRule,
        FormTable,
        StepMessages,
        BasicButton,
        FileUploadButton,
    },

    data() {
        return {
            step: 0,
            transactions: [],

            btnProps: {
                width: 200,
                height: 40,
                bgColor: '#fa7d20',
                textTransform: 'capitalize',
                fontWeight: 'semi-bold',
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['disabled', 'user', '_date_selected', 'findMoney', 'selectedUser', 'smallScreen']),

        midBtnLabel() {
            if (!this.step) return 'Iniciar'
            else if (this.step < 5) return 'Próxima'
            else return 'Finalizar'
        },

        componentProps() {
            const firstAndFinalSteps = !this.step || this.step > 5

            return {
                width: this.onboardingClient ? '100vw' : '75vw',
                height: this.onboardingClient && firstAndFinalSteps ? '100vh' : undefined,
                closeBtn: true,
                showRule: !this.onboardingClient,
                showAllForms: this.onboardingClient,
                showStepMessages: this.onboardingClient,
                formRowsQtd: this.onboardingClient ? [10, 21, 7] : [],
                uploadButtonPosition: this.onboardingClient ? 0 : 90,
                overlayStyles: this.onboardingClient
                    ? {
                          alignItems: 'unset',
                      }
                    : undefined,
                contentStyles:
                    this.onboardingClient && firstAndFinalSteps
                        ? {
                              width: '100%',
                              height: '83%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                          }
                        : { position: 'relative' },
            }
        },

        btnWidth() {
            return this.smallScreen ? undefined : undefined
        },
    },

    methods: {
        updateTransaction(transaction) {
            const index = this.transactions.findIndex((t) => t.id === transaction.id)

            if (index > -1) this.transactions[index] = transaction
            else this.transactions.push(transaction)
        },

        readSheet(file) {
            const workbook = new Excel.Workbook()

            workbook.xlsx
                .load(file, {
                    ignoreNodes: [
                        'dataValidations', // ignores the workbook's Data Validations
                    ],
                })
                .then((w) => {
                    const data = new ProcessExcelData(w.worksheets[1]).process()

                    Object.keys(data).forEach((key, index) => {
                        if (this.$refs[`form-table-${index + 1}`])
                            this.$refs[`form-table-${index + 1}`][0].pushUploadedData(data[key])
                    })
                })
                .catch(console.error)
        },

        nextStep() {
            if (this.onboardingClient) {
                if (!this.step) this.step++
                else this.step = 6
            } else this.step++
        },

        previousStep() {
            if (this.onboardingClient) this.step = 1
            else this.step--
        },

        endOnboarding() {
            const payload = {
                debit: [],
                credit: [],
                user: this.selectedUser,
                date: new Date(this._date_selected.inicio).toISOString(),
            }

            this.transactions.forEach((transaction) => {
                delete transaction.id

                Object.keys(transaction).forEach((key) => {
                    if (!transaction[key]) delete transaction[key]
                })

                transaction.value = Number(util.sanitizeMoney(transaction.value))

                if (transaction.category && transaction.value && transaction.date) {
                    if (transaction.recurrentType || transaction.lastInstallment) transaction.recurrent = true
                    if (transaction.lastInstallment) transaction.value *= transaction.lastInstallment
                    transaction.date = this.$moment(transaction.date).toISOString()
                    transaction.user = this.selectedUser
                    transaction.payment = this.findMoney(this.selectedUser)
                    transaction.paid = !!transaction.paid

                    if (transaction.transactionType === 'expenses') transaction.value *= -1

                    payload.debit.push(transaction)
                }
            })

            if (!payload.debit.length && !payload.credit.length) {
                this.$emit('close-modal')
                return
            }

            this.$emit('save-planning', payload)
        },
    },

    mounted() {
        if (this.user.skipTutorial) this.step += 1
    },
}
</script>

<style lang="scss" scoped>
.distribution-modal {
    .img-container {
        display: flex;
        justify-content: center;
        margin-bottom: 48px;

        img {
            width: 280px;
        }
    }

    video {
        width: 50vw;
        display: flex;
        margin: 0 auto 24px;
    }

    .file-upload-button {
        position: absolute;
        right: 32px;
    }
}
</style>
