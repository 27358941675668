import router from '../router/router'
import axios from 'axios'
import { environment } from '../common/environment'
import service from '../services/api.service'

import { mainStore } from '../store'
import sort from '../common/sort'

axios.defaults.headers.common['Content-Type'] = 'application/json'

const saveOnLocalStorage = (field, value) => {
    localStorage.setItem(field, value)
}

const getOnLocalStorage = (field) => {
    return localStorage.getItem(field) || ''
}

const removeFromLocalStorage = (field) => {
    localStorage.removeItem(field)
}

const clearLocalStorage = () => {
    Array.of('token', 'image', 'id').map((key) => {
        removeFromLocalStorage(key)
    })
}

const postUser = (userInformation) => {
    const store = mainStore()

    store.DISABLED()

    return service
        .post(environment.REGISTER_URL, userInformation)
        .then((response) => {
            return { status: response.status }
        })
        .catch((err) => {
            return { status: err.response.status, message: err.response.data.message, code: err.response.data.code }
        })
        .finally(() => store.DISABLED())
}

const authenticate = (credentials) => {
    const store = mainStore()

    store.DISABLED()

    return service
        .post(environment.LOGIN_URL, credentials)
        .then((res) => {
            const { accessToken, image, id, clients, isPlanner } = res.data

            if (isPlanner && clients?.length) return { accessToken, image, id, clients: clients.sort(sort.byName) }

            saveDataAndLogin({ accessToken, image, id })
        })
        .catch((err) => {
            let statusCode = err.response.status
            return statusCode
        })
        .finally(() => store.DISABLED())
}

const saveDataAndLogin = (loginData) => {
    if (loginData.accessToken) {
        saveOnLocalStorage('token', loginData.accessToken)
        saveOnLocalStorage('image', loginData.image)
        saveOnLocalStorage('id', loginData.id)

        router.push({ name: 'dashboard' })
    }
}

const logout = () => {
    const store = mainStore()

    clearLocalStorage()

    router.push({
        name: 'login',
    })

    store.LOGOUT()
}

const accountRecovery = (email) => {
    const store = mainStore()

    store.DISABLED()
    return service
        .post(environment.FORGOT_PASSWORD_URL, email)
        .catch((err) => {
            return err.response.status
        })
        .finally(() => store.DISABLED())
}

const updatePassword = (password, encryptedToken) => {
    const store = mainStore()

    store.DISABLED()

    return service
        .post(environment.UPDATE_PASSWORD_URL, { password, encryptedToken })
        .catch((err) => {
            return { status: err.response.status, message: err.response.data.message }
        })
        .finally(() => store.DISABLED())
}

const sendActivationEmail = () => {
    const store = mainStore()

    store.DISABLED()

    return service
        .post(environment.RESEND_EMAIL_ACTIVATE_ACCOUNT_URL)
        .catch((err) => {
            return { status: err.response.status, message: err.response.data.message }
        })
        .finally(() => store.DISABLED())
}

const activateAccount = (activationCode) => {
    const store = mainStore()

    store.DISABLED()

    return service
        .post(environment.ACTIVATE_ACCOUNT_URL, { activationCode })
        .catch((err) => {
            return { status: err.response.status, message: err.response.data.message }
        })
        .finally(() => store.DISABLED())
}

const resetAccount = () => {
    const store = mainStore()

    store.DISABLED()

    return service
        .post(environment.RESET_ACCCOUNT_URL, {})
        .catch((err) => {
            return { status: err.response.status, message: err.response.data.message }
        })
        .finally(() => store.DISABLED())
}

const deleteAccount = () => {
    const store = mainStore()

    store.DISABLED()
    const userId = getOnLocalStorage('id')

    return service
        .del(`${environment.USERS_URL}/${userId}`)
        .catch((err) => {
            return { status: err.response.status, message: err.response.data.message }
        })
        .finally(() => store.DISABLED())
}

export default {
    authenticate,
    logout,
    accountRecovery,
    updatePassword,
    sendActivationEmail,
    activateAccount,
    postUser,
    getOnLocalStorage,
    resetAccount,
    saveOnLocalStorage,
    deleteAccount,
    removeFromLocalStorage,
    clearLocalStorage,
    saveDataAndLogin,
}
