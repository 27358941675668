import sort from '@/common/sort'
import util from '@/common/util'
import helpers from '../helpers/getters.helper'

export default {
    checkCategorySortType() {
        return (categoryId, sortType = 'variable') => {
            let category = this.findById('categories', categoryId)
            category = category.category || category

            return category.sort === sortType
        }
    },

    /**
     * FILTRA CATEGORIAS POR GRUPO
     * @returns {any[]}
     * */
    filteredCategories() {
        return (group, userId) => {
            const filter = (category) => {
                let valid = true

                if (userId) valid = category.user === userId
                return valid && helpers.sameGroup(group, category.group)
            }

            return this.categories.filter((category) => filter(category)).sort(sort.byName)
        }
    },

    // FILTRAR TODAS AS SUBCATEGORIAS
    subcategories() {
        const { user } = this

        return this.categories.filter((category) => category.category && category.user === user._id).sort(sort.byName)
    },

    // AGRUPAR AS CATEGORIAS POR GRUPO
    primaryCategories() {
        const store = this

        return (group) => {
            const { user } = store
            let cats = [
                {
                    name: 'Receitas',
                    data: store
                        .filteredCategories('revenues')
                        .filter((category) => !category.category && category.user === user._id),
                },
                {
                    name: 'Despesas',
                    data: store
                        .filteredCategories('expenses')
                        .filter((category) => !category.category && category.user === user._id),
                },
            ]

            switch (group) {
                case 'revenues':
                    return cats[0].data
                case 'expenses':
                    return cats[1].data
                default:
                    return cats
            }
        }
    },

    // AGRUPAR CATEGORIAS POR TIPO
    groupedCategoriesByType() {
        const store = this

        return (group) => {
            const { user } = store

            let groups = [
                {
                    name: 'Essenciais',
                    data: [],
                },
                {
                    name: 'Dispensáveis',
                    data: [],
                },
            ]

            store.filteredCategories(group, user._id).map((category) => {
                if (category.category && category.essential) groups[0].data.push(category)
                else if (!category.category && category.type === 'essential') groups[0].data.push(category)
                else groups[1].data.push(category)
            })

            groups[0].data.sort(sort.byName)
            groups[1].data.sort(sort.byName)

            return groups
        }
    },

    /**
     * ENCONTRA A CATEGORIAS DEFAULT POR TIPO
     * @returns {any[]}
     */
    getDefautCategoryListByType() {
        return (type, group, permitedCategories = [], permitSubcategories, user = this.user._id) => {
            return this.categories.filter((category) => {
                let valid = true

                if (permitedCategories.length) valid = permitedCategories.includes(util.sanitize(category.name))
                if (type) {
                    if (category.category && permitSubcategories) {
                        valid = category.category.type === type
                    } else valid = category.type === type && valid
                }

                return helpers.sameGroup(group, category.group) && category.user === user && valid
            })
        }
    },

    /**
     * ENCONTRA A CATEGORIAS DEFAULT POR SORT TYPE
     * @returns {any[]}
     */
    getDefautCategoryListBySortType() {
        const { user } = this

        return (sort, permitedCategories = [], permitSubcategories, userId) => {
            const _id = userId || user._id

            return this.categories.filter((category) => {
                let valid = true

                if (permitedCategories.length) valid = permitedCategories.includes(util.sanitize(category.name))
                if (category.category && permitSubcategories) {
                    valid = category.category.sort === sort
                } else valid = category.sort === sort && valid

                return category.user === _id && valid
            })
        }
    },

    /**
     * ENCONTRAR UMA CATEGORIA PADRÃO PELO ID
     * @returns {any}
     */
    getDefaultCategoryById() {
        return (category) => {
            const id = category._id || category
            const _category = this.findById('categories', id)
            const defaultCategory = _category.category ? _category.category._id : _category._id

            if (defaultCategory.name) return defaultCategory
            return this.categories.find((category) => category._id === defaultCategory)
        }
    },

    /**
     * ENCONTRA A CATEGORIA PADRÃO PARA SALDO INICIAL DE CONTAS BANCÁRIAS
     **/
    findInitialBalanceCategory() {
        return this.filteredCategories('revenues').find((category) => category.name === 'Outras rendas')._id
    },

    /**
     * ENCONTRAR CATEGORIA DE ESTORNOS DO CARTÂO
     **/
    findReversalCategory() {
        return (
            this.categories.find(
                (category) => !category.category && category.group === 'invoice' && category.name === 'Estorno',
            )._id || '31f30f1c20eecddcfada1926'
        )
    },

    /** Encontra a categoria de faturas */
    findInvoiceCategory() {
        return this.categories.find((category) => category.group === 'invoice' && category.name === 'Fatura Cartão')
    },

    /**
     * Agrupa as categorias no novo modelo

     */
    newGroupedCategories() {
        /**
         * @param isTransactions - Verifica se é o model de cadastro de transações
         * @param permitSubcategories - Traz também as subcategories
         * @param userId - ID do usuário
         */
        return (isTransactions, permitSubcategories, userId) => {
            let essential = this.getDefautCategoryListBySortType('fixed', [], permitSubcategories, userId).sort(
                sort.byName,
            )
            let noessential = this.getDefautCategoryListBySortType('variable', [], permitSubcategories, userId).sort(
                sort.byName,
            )
            let debts = this.getDefautCategoryListByType('debts', 'expenses', [], permitSubcategories, userId).sort(
                sort.byName,
            )
            let investiments = this.getDefautCategoryListByType(
                undefined,
                'investiments',
                [],
                permitSubcategories,
                userId,
            ).sort(sort.byName)

            return [
                {
                    name: 'Despesas Fixas',
                    data: essential,
                },
                {
                    name: 'Despesas Variáveis',
                    data: noessential,
                },
                {
                    name: 'Dívidas & parcelas',
                    data: debts,
                },
                {
                    name: 'Investimentos',
                    data: investiments,
                },
            ]
        }
    },
}
