import date from '@/common/formatDate'
import util from '@/common/util'
import { categoriesList } from '../../common/lists'

export default {
    // CONFIGURAÇÕES DO GRÁFICO CHART NA TELA DE TRANSAÇÕES E DASHBOARD
    categoriesChartOptions(state) {
        const store = this

        return (group, paymentId, userId, isDashboard) => {
            const { user } = state
            let transactionPerCategory
            let options = {
                chartSeries: [],
                chartOptions: {
                    labels: [],
                    colors: [],
                },
                categories_id: [],
            }
            let sum = 0

            const categories = [...store.filteredCategories(group)]

            categories.forEach((element) => {
                const category = element.category ? element.category : element
                let categoryDefaultColor = categoriesList[util.sanitizeCategoryName(category)] || category.color

                const sameCategoryGroup = []

                if (element.category) sameCategoryGroup.push(element._id)
                else {
                    // Agrupando as categorias default equivalentes do outros membros de uma conta família
                    const membersCategories = state.categories
                        .filter(
                            (category) =>
                                category.user !== element.user &&
                                category.name === element.name &&
                                category.group === element.group &&
                                !category.category,
                        )
                        .map((c) => c._id)

                    membersCategories.forEach((id) => {
                        const index = categories.find((c) => c._id === id)
                        categories.splice(index, 1)
                    })

                    sameCategoryGroup.push(...[...membersCategories, element._id])
                }

                let isNotCreditCard = true

                if (paymentId) isNotCreditCard = store.findById('payments', paymentId).type !== 'credit'

                if (isNotCreditCard) {
                    transactionPerCategory = [
                        ...store
                            .filteredTransactions(group, undefined, userId)
                            .filter((transaction) => sameCategoryGroup.includes(transaction.category._id)),
                    ]

                    if (paymentId)
                        transactionPerCategory = transactionPerCategory.filter((t) => t.payment._id === paymentId)

                    if (group === 'expenses' && !paymentId) {
                        transactionPerCategory.push(
                            ...store.getItemsByCategory({ category: sameCategoryGroup, userId, paymentId }),
                        )
                    }
                } else {
                    transactionPerCategory = store
                        .getInvoiceItemsByPaymentId(paymentId)
                        .filter((item) => item.category._id === element._id)
                }

                sum = transactionPerCategory.reduce(function (prevValue, transaction) {
                    return prevValue + transaction.value
                }, 0)

                if (sum !== 0) {
                    options.chartSeries.push(Math.abs(sum))
                    options.chartOptions.labels.push(element.name)
                    options.chartOptions.colors.push(categoryDefaultColor)
                    options.categories_id.push(sameCategoryGroup)
                    sum = 0
                }
            })

            return options
        }
    },

    // CONFIGURAÇÕES DO LINE CHART TELA DASHBOARD
    daylyLineChart(state) {
        let series = [
            {
                name: 'receitas',
                data: [],
            },
            {
                name: 'despesas',
                data: [],
            },
        ]

        let revenues = this.transactionsWithoutMovBanc('revenues').filter((transaction) => transaction.paid)
        let expenses = this.transactionsWithoutMovBanc('expenses').filter((transaction) => transaction.paid)

        // Coletando as despesas do cartão
        expenses.push(...this.getDaylyInvoicesList)

        let day = new Date(state._date.inicio.slice(0, 4), state._date.inicio.slice(5, 7), 0).getDate()

        for (let i = 1; i <= day; i++) {
            series[0].data.push(
                revenues.reduce(function (prevValue, transaction) {
                    return Number(date.getDay(transaction.date)) === i ? prevValue + transaction.value : prevValue
                }, 0),
            )

            series[1].data.push(
                expenses.reduce(function (prevValue, transaction) {
                    return Number(date.getDay(transaction.date)) === i
                        ? prevValue + Math.abs(transaction.value)
                        : Math.abs(prevValue)
                }, 0),
            )
        }

        return series
    },

    // CONFIGURAÇÕES DO CHART DONUT DO SUMMARY CARD TELA DE FORMAS DE PAGAMENTO
    expensesPaymentsChart() {
        let options = {
            chartSeries: [],
            chartOptions: {
                labels: [],
            },
        }

        const userId = this.plannerWithouFamily ? this.mainUserId : undefined

        let transactions = this.transactionsWithoutMovBanc('expenses', userId).filter((transaction) => transaction.paid)
        let sum = 0

        sum = transactions
            .filter((transaction) => {
                let paymentId = transaction.payment._id || transaction.payment
                return this.findById('payments', paymentId).type === 'money'
            })
            .reduce(function (prevValue, transaction) {
                return prevValue + transaction.value
            }, 0)

        if (sum != 0) {
            options.chartSeries.push(Math.abs(sum))
            options.chartOptions.labels.push('Dinheiro')
        }

        sum = 0

        this.filteredPayments('debit', false, userId).forEach((element) => {
            sum = this.filterExpensesTransactionsPerPaymentId(element._id)
                .filter((transaction) => transaction.paid)
                .reduce(function (prevValue, transaction) {
                    return prevValue + transaction.value
                }, 0)

            if (sum != 0) {
                options.chartSeries.push(Math.abs(sum))
                options.chartOptions.labels.push(element.name)
            }

            sum = 0
        })

        this.filteredPayments('credit', false, userId).forEach((element) => {
            const invoice = this.findTransactionInvoice(element._id)

            if (invoice) {
                if (invoice.value) {
                    options.chartSeries.push(Math.abs(invoice.value))
                    options.chartOptions.labels.push(element.name)
                }
            }
        })

        return options
    },
}
