<template>
    <div class="transactions-list" :style="styles">
        <table class="transacao">
            <thead>
                <tr class="table-header">
                    <th class="date-col">DIA</th>
                    <th>DESCRIÇÃO</th>
                    <th v-if="!filter_payment" class="account-col" :class="{ 'categories-list': categoriesModal }">
                        CONTA
                    </th>
                    <th class="value-col" :class="{ fill: filter_payment }">VALOR</th>
                    <th v-if="showObservation" class="observation-col">OBSERVAÇÂO</th>
                    <th v-if="showUserCollumn" class="avatar-col">USUÁRIO</th>
                    <th
                        class="status"
                        :class="{ hide: onlyCreditCardTransactions, 'categories-list': categoriesModal }"
                    >
                        STATUS
                    </th>
                    <th v-if="isTransactions || showEditIcon"></th>
                    <th v-if="isTransactions" style="width: 56px"></th>
                </tr>
            </thead>

            <!-- SKELETON LOADER -->
            <tbody v-if="waiting">
                <tr v-for="(n, index) in 6" :key="index">
                    <td class="transacao-item transaction-day">
                        <v-skeleton-loader type="text" />
                    </td>

                    <td style="display: flex; align-items: center">
                        <v-skeleton-loader :type="types.head" class="head" />

                        <v-skeleton-loader
                            :type="types.arms"
                            style="max-width: 550px"
                            :width="randomWidth(transactionsScreen ? 250 : 100)"
                            class="arms"
                        />
                    </td>

                    <td class="transacao-item" :style="{ maxWidth: `${filter_payment ? 180 : 270}px` }">
                        <v-skeleton-loader
                            :width="randomWidth(filter_payment ? 100 : !transactionsScreen ? 50 : 150)"
                            style="display: flex; align-items: center"
                            type="text"
                        />
                    </td>

                    <td v-if="!filter_payment" class="transacao-item" style="max-width: 150px">
                        <v-skeleton-loader :width="randomWidth(transactionsScreen ? 55 : 30)" type="text" />
                    </td>

                    <td class="transacao-item" style="display: revert; padding-right: 20px">
                        <v-skeleton-loader :width="transactionsScreen ? 80 : 40" type="text" />
                    </td>
                </tr>
            </tbody>

            <!--  -->
            <transition-group v-else name="list" tag="tbody">
                <tr
                    data-cy="transaction-list"
                    v-for="(transaction, index) in sortedTransactions"
                    :key="transaction._id"
                >
                    <!-- DATE -->
                    <td class="transacao-item transaction-day">
                        <svg
                            v-if="showRecurrencyIcon(transaction)"
                            width="32px"
                            height="40px"
                            class="recurrent-icon"
                            viewBox="0 0 32 40"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g
                                id="Movimentações-Nova-Proposta"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                                transform="translate(-115.000000, -429.000000)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <g
                                    id="Group-14"
                                    transform="translate(115.000000, 429.000000)"
                                    :stroke="[
                                        transaction.transactionType === 'expenses' ? expensesColor : revenuesColor,
                                    ]"
                                    stroke-width="2"
                                >
                                    <path
                                        d="M17.6534689,28.9655172 C24.0974421,27.7147759 28.9655172,21.9945714 28.9655172,15.1270253 C28.9655172,7.34392895 22.7129422,1.03448276 15,1.03448276 C7.2870578,1.03448276 1.03448276,7.34392895 1.03448276,15.1270253 C1.03448276,19.560835 3.06360498,23.5164071 6.23589865,26.0997327"
                                        id="Oval-5-Copy"
                                        transform="translate(15.000000, 15.000000) rotate(-92.000000) translate(-15.000000, -15.000000) "
                                    ></path>
                                    <polygon
                                        id="Path-8"
                                        :fill="[
                                            transaction.transactionType === 'expenses' ? expensesColor : revenuesColor,
                                        ]"
                                        points="29.0866671 12.4137931 31.0344828 5.17241379 26.8965517 6.53093586 28.2159573 8.86089003"
                                    ></polygon>
                                </g>
                            </g>
                        </svg>
                        {{ labelDate(transaction.date) }}
                    </td>

                    <!-- DESCRIPTION -->
                    <td class="transacao-item description" :class="{ 'half-width': calendar }">
                        <img
                            v-if="isTransactions"
                            :src="require('@/assets/img/categories/' + getImgUrl(transaction.category))"
                            class="avatar item"
                            :class="[
                                transaction.paid ? sanitizeCategoryName(transaction.category) : 'unpaid',
                                transaction.category.group,
                                transaction._id,
                            ]"
                        />

                        <div style="width: 100%">
                            <div :title="generateTitle(transaction)" class="limited name">
                                {{ transaction.name }}
                                <small v-if="transaction.recurrent && transaction.lastInstallment" class="installment">
                                    [ {{ transaction.recurrentIndex }} / {{ transaction.lastInstallment }} ]
                                </small>
                            </div>

                            <span v-if="isTransactions" class="item category limited">{{
                                transaction.category.name
                            }}</span>
                        </div>
                    </td>

                    <!-- PAYMENT -->
                    <td v-if="!filter_payment && transaction.payment" class="transacao-item">
                        <router-link
                            class="limited"
                            :style="{ width: !transactionsScreen ? '150px' : '100%' }"
                            :to="`/movimentacoes/${transaction.payment._id}`"
                        >
                            <span class="limited">
                                {{ transaction.payment.name }}
                            </span>
                        </router-link>
                    </td>

                    <!-- VALUE -->
                    <td class="transacao-item">
                        <span
                            class="font-demibd"
                            :class="[transactionValue(transaction) > 0 ? 'green-text' : 'red-text']"
                        >
                            {{ formatNum(Math.abs(transactionValue(transaction))) }}
                        </span>
                    </td>

                    <td v-if="showObservation" class="transacao-item" :title="transaction.description || ''">
                        <span class="limited">{{ transaction.description }}</span>
                    </td>

                    <!-- AVATAR -->
                    <td v-if="showUserCollumn" class="transacao-item avatar">
                        <div>
                            <avatar :avatar="transaction.user.image" :letter="transaction.user.name.charAt(0)" />
                        </div>
                    </td>

                    <!-- STATUS BOX -->
                    <td
                        v-if="!onlyCreditCardTransactions && optionAllowed(transaction)"
                        class="transacao-item status"
                        :class="{ 'half-padding': calendar }"
                    >
                        <div v-for="(config, innerIndex) in paidIconConfig" :key="innerIndex">
                            <a
                                v-if="
                                    notCreditCard(transaction.payment) &&
                                    transaction.transactionType === config.condition
                                "
                                class="icon ion-md-checkmark"
                                :class="{ [config.class]: transaction.paid }"
                                @click="
                                    ;[
                                        canPaid(transaction)
                                            ? isPaid(transaction)
                                            : showMsg(
                                                  'NÃO É POSSÍVEL MUDAR STATUS DESSA TRANSAÇÃO',
                                                  index,
                                                  onlyExternalTransactions ? '-15px' : '45px',
                                              ),
                                    ]
                                "
                                ><ion-icon name="checkmark-outline"></ion-icon
                            ></a>
                        </div>
                    </td>

                    <!-- EDIT ICON -->
                    <td
                        v-if="showEditIcon || showActionIcon(transaction)"
                        class="transacao-item status"
                        :class="{ 'half-padding': calendar }"
                    >
                        <a
                            @click="
                                ;[
                                    permitEdit(transaction) && notParceledOutCreditCardExpense(transaction)
                                        ? editTransaction(transaction)
                                        : showMsg('NÃO É POSSÍVEL EDITAR ESSA TRANSAÇÃO', index, '-25px'),
                                ]
                            "
                        >
                            <edit-icon />
                        </a>
                    </td>

                    <!-- DELETE ICON -->
                    <td
                        v-if="showActionIcon(transaction)"
                        class="transacao-item status"
                        :class="{ 'half-padding': calendar }"
                    >
                        <a
                            v-if="canDelete(transaction)"
                            @click="
                                ;[
                                    canDelete(transaction)
                                        ? deleteTransaction(transaction)
                                        : showMsg('NÃO É POSSÍVEL EXCLUIR ESSA TRANSAÇÃO', index, '-80%'),
                                ]
                            "
                        >
                            <delete-icon />
                        </a>
                    </td>

                    <message v-if="index === pos" :position="position" :msg="msg" />

                    <!-- INVOICE STATUS BOX -->
                    <td class="transacao-item" v-if="transaction.invoiceReferenceId">
                        <invoice-payment :transaction="transaction" @attBalancos="attBalancos" />
                    </td>

                    <div class="_border"></div>
                </tr>

                <tr v-if="!sortedTransactions.length" key="nodata" style="height: unset">
                    <td class="no-data" colspan="7">
                        <img :src="$variables.listIcon" alt="" />
                        <div>
                            <p>Hmmm… Nada por aqui…</p>
                            <p>Use os botões abaixo para conectar uma conta ou registrar transações manualmente</p>
                        </div>
                    </td>
                </tr>
            </transition-group>
        </table>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'

import Message from '../PopUpMsg.vue'
import EditIcon from '../icons/EditIcon.vue'
import DeleteIcon from '../icons/DeleteIcon.vue'
import Avatar from '../icons/Avatar.vue'
import InvoicePayment from '../forms/InvoicePayment.vue'

import _date from '@/common/formatDate'
import util from '@/common/util'
import sort from '@/common/sort'
import _request from '@/common/request'

export default {
    name: 'TransactionsList',

    components: {
        Message,
        InvoicePayment,
        EditIcon,
        DeleteIcon,
        Avatar,
        InvoicePayment,
    },

    props: {
        transactions: {
            type: Array,
            required: true,
        },
        isTransactions: {
            type: Boolean,
            default: true,
        },
        filter_payment: {
            type: String,
            default: null,
        },
        showObservation: Boolean,
        categoriesModal: Boolean,
        styles: String,
        showEditIcon: Boolean,
    },

    data() {
        return {
            delModal: false,
            payload: {
                mutation: '',
            },
            transactionType: '',
            isRecurrent: false,
            pos: null,
            position: '',
            msg: '',
            types: {
                head: 'avatar',
                arms: 'sentences',
                foot: 'sentences',
            },
            paidIconConfig: [
                { condition: 'revenues', class: 'green-text' },
                { condition: 'expenses', class: 'red-text' },
            ],
        }
    },

    computed: {
        ...mapState(mainStore, [
            '_date_selected',
            '_date',
            'user',
            'waiting',
            'findById',
            'getInvoiceByPaymentId',
            'getCreditCardInvoiceByID',
            'getRecurrencyInvoiceSum',
            'isOttoFamily',
        ]),

        sortedTransactions: function () {
            let transactionsList = []

            if (this.filter_payment) {
                if (this.notCreditCard(this.filter_payment)) {
                    transactionsList.push(
                        ...this.transactions.filter((transaction) => transaction.payment._id === this.filter_payment),
                    )
                } else {
                    transactionsList.push.apply(transactionsList, this.transactions)
                }
            } else {
                transactionsList.push.apply(transactionsList, this.transactions)
            }

            return transactionsList.sort(sort.byDateAndValue)
        },

        onlyExternalTransactions() {
            return (
                this.transactions.find(
                    (transaction) => !transaction.externalId && transaction.user._id === this.user._id,
                ) === undefined
            )
        },

        onlyCreditCardTransactions() {
            return (
                this.transactions
                    .filter((transaction) => !!transaction.payment)
                    .find((transaction) => this.findById('payments', transaction.payment._id).type !== 'credit') ===
                    undefined && !this.isTransactions
            )
        },

        calendar() {
            return this.$route.name === 'calendar'
        },

        showUserCollumn() {
            return this.isOttoFamily && !this.calendar
        },

        transactionsScreen() {
            return this.isTransactions && !this.calendar
        },

        expensesColor() {
            return process.env.VUE_APP_EXPENSES
        },

        revenuesColor() {
            return process.env.VUE_APP_REVENUES
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED', 'SET_MISC_DATA']),

        formatNum(number) {
            return util.formatNumber(number)
        },

        showMsg(msg, index, position) {
            this.pos = index
            this.msg = msg
            this.position = position
            setTimeout(() => {
                this.pos = null
            }, 3000)
        },

        canPaid(transaction) {
            return (
                !transaction.bankingOperationReferenceId &&
                transaction.value !== 0 &&
                !transaction.externalId &&
                transaction.user._id === this.user._id
            )
        },

        showRecurrencyIcon(transaction) {
            return transaction.recurrent && !transaction.lastInstallment && !this.calendar
        },

        canDelete(transaction) {
            return !transaction.invoiceReferenceId && !transaction.externalId
        },

        isLinkedTransaction(id) {
            return this.findById('linkedtransactions', id)
        },

        transactionValue(transaction) {
            const { value, invoiceReferenceId, paid, invoiceBalance } = transaction

            if (invoiceReferenceId && !paid) return invoiceBalance
            return value
        },

        isPaid(transaction) {
            if (this.isLinkedTransaction(transaction._id)) {
                this.$emit('pay-calendar-transaction', transaction._id)
                return
            }

            let data = { ...transaction }

            delete data['recurrent']

            if (!data.paid) data.paid = true
            else data.paid = !data.paid

            this.DISABLED()

            _request
                .patch({ collection: 'transactions', data, _id: data._id })
                .then(async (resp) => {
                    await _request.getById({ collection: 'payments', _id: resp.payment._id })
                    await this.attBalancos()
                })
                .finally(() => this.DISABLED())
        },

        async attBalancos() {
            this.DISABLED()

            _request
                .fetch([
                    { collection: 'balances', queryParams: this._date_selected },
                    { collection: 'balancesChart', queryParams: this._date_selected },
                ])
                .then(() => this.$emit('attBalancos'))
                .finally(() => this.DISABLED())
        },

        sanitize(string) {
            return util.sanitize(string)
        },

        generateTitle(transaction) {
            let title = transaction.name

            if (transaction.recurrent && transaction.lastInstallment) {
                title = title.concat(` [${transaction.recurrentIndex}/${transaction.lastInstallment}]`)
            }

            return title
        },

        randomWidth(base) {
            return util.randomWidth(base)
        },

        sanitizeCategoryName(category) {
            return util.sanitizeCategoryName(category)
        },

        getImgUrl(category) {
            return util.getImgUrl(category)
        },

        editTransaction(transaction) {
            const linkedTransaction = this.isLinkedTransaction(transaction._id)

            if (linkedTransaction) {
                this.$root.$emit('editTransactionEvent', { ...linkedTransaction }, 'EditLinkedTransaction')
                return
            }

            let data = { ...transaction }

            data.date = data.date.toString().slice(0, 10)

            this.SET_MISC_DATA({ attr: 'hideCategoriesModal', value: true })

            this.$root.$emit('editTransactionEvent', data)
        },

        deleteTransaction(transaction) {
            const invoice = this.getInvoiceByPaymentId(this.filter_payment)
            const isLinkedTransaction = this.isLinkedTransaction(transaction._id)
            const collection = isLinkedTransaction ? 'linkedtransactions' : 'transactions'
            const _paid = isLinkedTransaction ? false : transaction.paid

            const text =
                'Tem certeza que deseja excluir ' +
                (isLinkedTransaction
                    ? 'esta transação automática do calendário'
                    : 'esta(s) ' + util.translateTransaction(transaction.transactionType).concat('s'))

            let modal = {
                data: {
                    _recId: transaction.recurrent ? transaction.recurrentTransactionId : null,
                    _recIndex: transaction.recurrentIndex,
                    _bankOpId: transaction.bankingOperationReferenceId,
                    _paymentId: transaction.payment._id,
                    _bankOpPayId: transaction.bankingOperationPayment,
                    _value: transaction.value,
                    _id: transaction._id,
                    _paid,
                    collection,
                },
                recurrent: transaction.recurrent,
                text,
            }

            if (invoice) {
                modal.data = Object.assign(modal.data, {
                    sub_id: transaction._id,
                    _id: invoice._id,
                    _installment: transaction.lastInstallment ? true : false,
                    collection: 'invoices',
                })

                if (transaction.lastInstallment) modal.strongText = ' (Todas as parcelas seram deletadas)'
            }

            // dados enviados para o component ConfimationModal
            this.$root.$emit('openConfirmationModalEvent', modal)
        },

        notCreditCard(payment) {
            if (payment) {
                const _payment = this.findById('payments', payment._id || payment)

                if (_payment) return _payment.type !== 'credit'
                return true
            }

            return true
        },

        permitEdit(transaction) {
            return (
                !transaction.bankingOperationReferenceId &&
                !transaction.invoiceReferenceId &&
                !transaction.paymentInvoiceReferenceId
            )
        },

        showActionIcon(transaction) {
            return (
                !transaction.invoiceReferenceId &&
                this.isTransactions &&
                transaction.user._id === this.user._id &&
                this.optionAllowed(transaction)
            )
        },

        optionAllowed(transaction) {
            return !transaction.invoiceReferenceId && transaction.payment
        },

        notParceledOutCreditCardExpense(transaction) {
            const isCreditCard = !this.notCreditCard(transaction.payment._id)

            return (isCreditCard && !transaction.lastInstallment) || !isCreditCard
        },

        labelDate(date) {
            let day = _date.getDay(date)
            return day < 10 ? '0' + day : day
        },
    },
}
</script>

<style lang="scss" scoped>
@include empty-list();
@include easy-avatar();

.transactions-list {
    box-shadow: 0px 0px 6px #00000026;

    tr {
        position: relative;
    }
}

table.transacao {
    width: 100%;
    border-collapse: separate;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
}

.table-header {
    height: 30px !important;
    color: $text-black;
    box-shadow: none !important;
    border-radius: 2px 2px 0 0;
    position: relative;

    .observation-col {
        width: 20%;
    }

    .account-col {
        width: 30%;

        &.categories-list {
            width: 20%;
        }
    }

    .value-col {
        width: 20%;

        &.fill {
            width: 46%;
        }
    }

    .status {
        position: absolute;
        height: 30px;
        width: 70px;
        text-align: center;
        line-height: 30px;

        &.categories-list {
            position: relative;
        }
        &.hide {
            opacity: 0;
            width: 0;
        }
    }
}

.installment {
    color: #919090;
    font-weight: bold;
    line-height: 16px;
}

.table-header {
    background-color: $list-blue !important;

    & > th {
        font-size: 13px;
        font-weight: 500;
        color: $darker-blue;
    }
}

.transaction-day {
    font-weight: 600;
    color: $blueish-gray;
}

.day {
    height: 20px;
    color: #848484;
    text-align: center !important;
}

.day span {
    font-size: 13px;
    font-weight: 500;
}

.table-header th:first-child,
.transacao-item:first-child {
    position: relative;
    width: 24px;
    padding-left: 16px;
    padding-right: 8px;
    text-align: center;
}

.recurrent-icon {
    position: absolute;
    left: 11px;
    margin-top: -8px;
}

.transacao-item:last-child {
    width: 150px;
    padding: 0;
    display: flex;
    justify-content: space-around;

    &.fill {
        width: max-content;
    }
}

.transacao-item.status {
    text-align: center;

    &.half-padding {
        padding: 0 8px;
    }
}

.transacao-item.avatar {
    & > div {
        margin: auto;
        width: 60%;
    }
}

.transacao-item.description {
    display: flex;
    align-items: center;
    height: 55px;
    width: 350px;

    &.half-width {
        width: 240px;
    }

    & > img {
        margin-right: 10px;
    }

    & .category {
        font-size: 14px;
        font-weight: 500;
        color: $blueish-gray;
        display: inherit;
    }
}

.transacao tr:nth-child(1) {
    padding: 10px;
    border: none;
}

.transacao tr {
    height: 57px;
    background-color: #ffffff;

    &:first-child {
        & .border {
            display: none;
        }
    }
}

.transacao td,
.transacao th {
    padding: 0 16px;
    text-align: left;
    vertical-align: middle;
}

.transacao td a {
    color: #bbbbbb;
    font-weight: 600;
    line-height: 19px;
}

a:hover {
    .limited {
        text-decoration: underline;
    }
}

.limited {
    display: inline-block;
    white-space: nowrap;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $darker-blue;
    font-weight: normal;
}

.icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    padding-top: 2px;
    border-radius: 100%;
    color: $inactive-icon;
    font-size: 22px;
    text-align: center;

    &:hover {
        background-color: #ecf0fd;
        color: $active-icon;
    }

    &.placeholder {
        pointer-events: none;
    }
}

.avatar {
    width: 30px;
    border-radius: 50%;
    vertical-align: middle;
}

.text-center {
    text-align: center !important;
}

.font-md {
    font-weight: 500;
}

.font-demibd {
    font-weight: 600;
}

.green-text {
    color: $revenue-green !important;
}

.red-text {
    color: $expense-red !important;
}

._border {
    box-sizing: border-box;
    border: 1px solid #eef1f6;
    position: absolute;
    width: 97%;
    left: 1.5%;
}
</style>
