<template>
    <label class="stacked-select" @click="active" :class="[err ? 'error' : '', labelColor, { disabled }]">
        <span :id="`${id}-label`" class="stacked-label" :class="isRequired && 'required'">{{ label }}</span>

        <!-- SELECT NORMAL -->
        <select
            v-if="!isGroupSelect"
            :id="id"
            class="select"
            :class="{ 'no-label': align }"
            :style="{ fontSize: font + 'px' }"
            v-model="selected"
            :disabled="disabled"
            @change="onChange"
            @blur="blurred"
            @click="active"
            data-cy="select-normal"
        >
            <option
                v-for="(element, index) in model"
                v-html="element.name || element"
                :key="index"
                :value="!notTransaction ? element._id : element"
            ></option>
        </select>

        <!-- GROUP SELECT -->
        <select
            v-else
            :id="id"
            class="select"
            :class="{ 'no-label': align }"
            :style="{ fontSize: font + 'px' }"
            :disabled="disabled"
            v-model="selected"
            @change="onChange"
            @blur="blurred"
            @click="active"
            :data-cy="dataCy"
        >
            <optgroup v-for="(group, index) in model" :key="index" :label="group.name">
                <option
                    v-for="(element, index) in group.data"
                    :key="index"
                    :value="!notTransaction ? element._id : element"
                >
                    {{ element.name }}
                </option>
            </optgroup>
        </select>

        <div v-if="err" class="error-msg-container">
            <span :style="{ fontSize: errorFontSize + 'px' }">{{ errorMsg.toLowerCase() }}</span>
        </div>

        <div v-if="!disabled" class="arrow right"></div>
    </label>
</template>

<script>
export default {
    name: 'StackedSelect',
    props: {
        label: {
            type: String,
            required: true,
        },
        labelColor: {
            type: String,
            default: 'default-blue',
        },
        model: {
            type: [Array, Number],
            required: true,
        },
        previousSelected: {
            type: [Object, Number, String],
            default: null,
        },
        errorValidation: Boolean,
        errorMsg: {
            type: String,
            default: 'Campo Obrigatório',
        },
        isGroupSelect: {
            type: Boolean,
            default: false,
        },
        notTransaction: {
            type: Boolean,
            default: false,
        },
        font: {
            type: [Number, String],
            default: 16,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: Boolean,
        customPositioning: String, // para ajustar a exclamação
        errorFontSize: {
            type: [Number, String],
            default: 12,
        },
        align: Boolean,
        ph: {
            type: String,
            default: 'Escolha uma opção',
        },
        dataCy: {
            type: String,
            default: '',
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        reviewValue: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selected: null,
            err: false,
            id: `select-${this._uid}`,
        }
    },

    methods: {
        onChange(e) {
            let value = e.target.value
            let parentElClassList = document.getElementById(this.id).parentElement.classList
            let labelClassList = document.getElementById(this.id + '-label').classList

            if (!value) {
                if (this.required) this.err = true
            } else {
                this.err = false
            }

            labelClassList.remove(this.labelColor)
            parentElClassList.remove('active')

            this.$emit('change', this.selected)
        },

        blurred(e) {
            let value = e.target.value
            let parentElClassList = document.getElementById(this.id).parentElement.classList
            let labelClassList = document.getElementById(this.id + '-label').classList

            if (!value) {
                if (this.required) this.err = true
            } else {
                this.err = false
            }

            labelClassList.remove(this.labelColor)
            parentElClassList.remove('active')

            this.$emit('blur', value)
        },

        active() {
            this.err = false
            document.getElementById(this.id).parentElement.classList.add('active')
            document.getElementById(this.id + '-label').classList.add(this.labelColor)
        },
    },

    watch: {
        previousSelected: {
            immediate: true,
            handler(data) {
                if (data || this.reviewValue) this.selected = data._id || data
            },
        },

        errorValidation: {
            immediate: true,
            handler(err) {
                this.err = err
            },
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.notTransaction) {
                this.selected = this.previousSelected
            }
        })
    },
}
</script>

<style lang="scss" scoped>
.stacked-select {
    display: block;
    height: 56px;
    border: 1px solid #bebebe;
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 16px;
    position: relative;

    &.default-blue:hover,
    &:focus,
    &.active {
        border: 2px solid $btn-blue;
        background: white !important;
    }

    &.default-blue:hover > .stacked-label {
        color: $btn-blue !important;
    }

    &.disabled {
        border: 1px solid #b7b8b9;
        pointer-events: none;

        .stacked-label,
        input {
            color: #b7b8b9;
        }

        &:hover {
            border: 1px solid #b7b8b9;
        }
    }
}

.error {
    border: 2px solid $hint-red;
    background: $hint-bg-red;

    .stacked-label {
        color: $hint-red !important;
    }
}

.error-msg-container {
    margin-top: 6px;
    font-size: 13px;
    color: $hint-red;
    text-align: left;
    padding-left: 4px;
    text-transform: capitalize;
    z-index: 3;
}

.stacked-label {
    position: absolute;
    top: 8px;
    left: 10px;
    background-color: transparent;
    font-size: 13px;
    width: max-content;
    font-weight: 500;
    z-index: 2;
    pointer-events: none;

    &.default-blue {
        color: $btn-blue;
    }
}

.stacked-select .select {
    width: 100%;
    height: 100%;
    padding: 30px 10px 8px;
    background-color: transparent;
    border: 0;
    color: #646464;

    &.no-label {
        padding: 16px 15px;
    }
}

option,
optgroup {
    background: white !important;
}

select[disabled] {
    color: #9e9e9e !important;
    cursor: not-allowed;
}

.arrow.right {
    position: absolute;
    right: 20px;
    top: 50%;
    z-index: 2;
}

.required:after {
    content: ' *';
    color: red;
}
</style>
