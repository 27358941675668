import util from '../../common/util'

export default {
    // PEGA DA TRANSAÇÃO DE UMA FATURA
    getInvoiceTransaction(state) {
        return (invoiceId) => {
            return state.transactions.find((transaction) => transaction.invoiceReferenceId === invoiceId)
        }
    },

    // FILTRAR TRANSAÇÕES POR GRUPO E OUTROS CAMPOS
    filteredTransactions() {
        return (transactionType, aditionalFilters, userId, otherTransactions) => {
            const transactions = otherTransactions || this.transactions

            if (transactionType === 'all')
                return transactions.filter((transaction) => util.customFilter(aditionalFilters, transaction, userId))
            return transactions.filter((transaction) =>
                util.customFilter({ ...aditionalFilters, transactionType }, transaction, userId),
            )
        }
    },

    // FILTRAR TRANSAÇÕES SEM MOVIMENTAÇÕES BANCÁRIAS
    transactionsWithoutMovBanc() {
        return (group, userId, otherTransactions) => {
            return this.filteredTransactions(group, undefined, userId, otherTransactions).filter(
                (transaction) => !transaction.bankingOperationReferenceId,
            )
        }
    },

    /**
     * FILTRAR TRANSAÇÕES POR CATEGORIA E GRUPO
     * */
    filterTransactionsPerCategory() {
        return ({ group, category, isBudget, userId, paymentId, otherTransactions, otherInvoices }) => {
            let transactionList = this.filteredTransactions(
                group,
                isBudget ? { paid: true } : undefined,
                userId,
                otherTransactions,
            ).filter((transaction) => {
                let valid = true

                if (isBudget) {
                    if (Array.isArray(category)) return category.includes(transaction.category._id)
                    return transaction.category._id === category
                }

                if (paymentId) valid = transaction.payment._id === paymentId

                if (Array.isArray(category)) return valid && category.includes(transaction.category._id)
                return valid && transaction.category._id === category
            })

            if (group === 'expenses')
                transactionList.push(...this.getItemsByCategory({ category, isBudget, paymentId, otherInvoices }))

            return transactionList
        }
    },

    // FILTRAR DESPESAS POR PAGAMENTO
    filterExpensesTransactionsPerPaymentId(state) {
        const store = this

        return (payment_id) => {
            return store.transactionsWithoutMovBanc('expenses').filter((transaction) => {
                return transaction.payment._id
                    ? transaction.payment._id === payment_id
                    : transaction.payment === payment_id
            })
        }
    },

    // ENCONTRAR A 5 DESPESAS MAIS PRÓXIMAS DO DIA ATUAL
    nextExpenses(state) {
        let date = new Date()
        let nextDespesas = this.filteredTransactions('expenses').filter(
            (elem) =>
                Number(elem.date.slice(8, 10)) >= date.getDate() && elem.bankingOperationReferenceId === undefined,
        )

        return nextDespesas
    },
}
